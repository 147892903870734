import socket from '~/lib/socket'
import { cloneDeep } from 'lodash-es'

type Commit = {
  token: any
  svgJson: any
  guideSvg: Node | null
}
export const useSocket = () => {
  const commit = ({ token, svgJson, guideSvg }: Commit) => {
    const commitData = cloneDeep(svgJson)
    guideSvg && commitData.children.push(cloneDeep(guideSvg))
    socket.emit('commit', { token, document: JSON.stringify(commitData) })
  }

  return { commit }
}
