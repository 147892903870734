import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useAlmagest,
  historiesModule,
  targetModule,
  ShapeTypes,
} from '@naire-seisakusho/react-almagest'

import {
  PropertiesFill,
  PropertiesStroke,
  PropertiesText,
} from '~/components/molecules'
import { hasProperties } from '~/utils/hasProperties'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const { selectTargetId } = targetModule
const {
  storeAttributes,
  replaceElement,
  selectCurrentSvgJson,
} = historiesModule

const Properties: React.FC = () => {
  const dispatch = useDispatch()
  const targetId = useSelector(selectTargetId)
  const svgJson = useSelector(selectCurrentSvgJson)

  const { afterCommit, shapeRefs } = useAlmagest()

  const shapeType = React.useMemo(() => {
    if (!targetId) return 'sight'

    return (
      svgJson?.children?.find((child) => child.attributes.id === targetId)
        ?.almagest?.shapeType || 'sight'
    )
  }, [targetId])

  const handleStoreAttributes = (attributes: any) => {
    if (!targetId) return
    dispatch(
      storeAttributes({ id: targetId, attributes, callback: afterCommit })
    )
  }

  const handleEffectAttributes = (attributes: any) => {
    if (!targetId) return
    shapeRefs[targetId].shapeRef.current.setAttributes(attributes)
  }

  const handleStoreElement = (element: any) => {
    if (!targetId) return
    dispatch(replaceElement({ id: targetId, element, callback: afterCommit }))
  }

  const attributes =
    svgJson.children.find((child) => child.attributes.id === targetId)
      ?.attributes || {}

  const menu = React.useMemo(() => hasProperties(shapeType).properties, [
    shapeType,
  ])

  const element = svgJson?.children?.find(
    (child) => child.attributes.id === targetId
  )

  if (!targetId)
    return (
      <div className="propertyEdit">
        <div className="propertyEdit__attention">
          <p className="sideContentAttention">
            配置した文字：画像・図形の選択や削除、重ね順の変更をすることができます。
          </p>
        </div>
        <div className="propertyEdit__noItem">
          選択されたオブジェクトはありません
        </div>
      </div>
    )

  return (
    <div className="propertyEdit">
      <div className="propertyEdit__attention">
        <p className="sideContentAttention">
          配置した文字：画像・図形の選択や削除、重ね順の変更をすることができます。
        </p>
      </div>
      {/* fill */}
      {menu.includes('fill') && (
        <div className="propertyEdit__group">
          <h3 className="propertyEdit__title">塗り</h3>
          <div className="propertyEdit__content">
            <PropertiesFill
              attributes={{ color: attributes.fill as string }}
              effectAttributes={handleEffectAttributes}
              storeAttributes={handleStoreAttributes}
            />
          </div>
        </div>
      )}

      {/* stroke */}
      {menu.includes('stroke') && (
        <div className="propertyEdit__group">
          <h3 className="propertyEdit__title">
            {`${shapeType === ShapeTypes.line ? '' : '枠'}`}線
          </h3>
          <div className="propertyEdit__content">
            <PropertiesStroke
              shapeType={shapeType}
              attributes={{
                stroke: attributes.stroke as string,
                strokeWidth: attributes['stroke-width'] as string,
                strokeDasharray: attributes['stroke-dasharray'] as string,
              }}
              effectAttributes={handleEffectAttributes}
              storeAttributes={handleStoreAttributes}
            />
          </div>
        </div>
      )}

      {/* text */}
      {shapeType == ShapeTypes.text && (
        <PropertiesText
          element={element}
          effectAttributes={handleEffectAttributes}
          storeAttributes={handleStoreAttributes}
          storeElement={handleStoreElement}
        />
      )}
    </div>
  )
}

export default Properties
