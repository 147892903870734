import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'shapes',
  initialState: {
    clipboard: null,
  },
  reducers: {
    setClipboard: (state, action) => {
      state.clipboard = action.payload
    },
  },
})

export const { setClipboard } = slice.actions

export const selectClipboard = state => state.shapes.clipboard

export default slice.reducer
