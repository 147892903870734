type ContextMenuList =
  | 'undo' // 戻る
  | 'redo' // 進む
  | 'cut' // 切り取り
  | 'copy' // コピー
  | 'paste' // 貼り付け
  | 'remove' // 削除
  | 'fill' // 塗り
  | 'stroke' // 枠線
  // | 'flipVertical' // 上下反転
  // | 'flipHorizontal' // 左右反転
  | 'bringToFront' // 最前面へ
  | 'bringForward' // 前面へ
  | 'sendBackward' // 背面へ
  | 'sendToBack' // 最背面へ

type PropertiesMenuList =
  | 'fill' // 塗り
  | 'stroke' // 枠線の色

type ShapeMenuList = {
  context: ContextMenuList[]
  properties: PropertiesMenuList[]
}

type MenuList = { [T in ShapeType | 'sight']: ShapeMenuList }

const drawingSet: ShapeMenuList = {
  context: [
    'undo',
    'redo',
    'cut',
    'copy',
    'paste',
    'remove',
    'fill',
    'stroke',
    'bringToFront',
    'bringForward',
    'sendBackward',
    'sendToBack',
  ],
  properties: ['fill', 'stroke'],
}

const menuList: MenuList = {
  // 背景
  sight: {
    context: ['undo', 'redo', 'paste'],
    properties: [],
  },
  // 直線
  line: {
    context: [
      'undo',
      'redo',
      'cut',
      'copy',
      'paste',
      'remove',
      'stroke',
      'bringToFront',
      'bringForward',
      'sendBackward',
      'sendToBack',
    ],
    properties: ['stroke'],
  },
  // 四角形
  rect: drawingSet,
  // 正方形
  square: drawingSet,
  // 真円
  circle: drawingSet,
  // 楕円
  ellipse: drawingSet,
  // 二等辺三角形
  triangleIsosceles: drawingSet,
  // 直角三角形(左が直角)
  triangleRightLeft: drawingSet,
  // 直角三角形(右が直角)
  triangleRightRight: drawingSet,
  // 正三角形
  triangleEquilateral: drawingSet,
  // 画像
  image: {
    context: [
      'undo',
      'redo',
      'cut',
      'copy',
      'paste',
      'remove',
      'stroke',
      'bringToFront',
      'bringForward',
      'sendBackward',
      'sendToBack',
    ],
    properties: [],
  },
  // SVG
  preset: {
    context: [
      'undo',
      'redo',
      'cut',
      'copy',
      'paste',
      'remove',
      'bringToFront',
      'bringForward',
      'sendBackward',
      'sendToBack',
    ],
    properties: [],
  },
  // テキスト
  text: {
    context: [
      'undo',
      'redo',
      'cut',
      'copy',
      'paste',
      'remove',
      // 'fill',
      // 'stroke',
      'bringToFront',
      'bringForward',
      'sendBackward',
      'sendToBack',
    ],
    properties: [
      // 'fill',
      // 'stroke'
    ],
  },
}

export const hasProperties = (type: ShapeType | 'sight') => {
  return menuList[type]
}
