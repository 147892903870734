// byenvに移すべきサブコンポーネント有無を要確認
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import {
  ShapeTypes,
  ShapeTypeNameDefines,
} from '@naire-seisakusho/react-almagest'

import { useOnDropSourceToEditorCreator } from '~/hooks/onDropSourceToEditorCreator'
import {
  useFetchPhotosQuery,
  useUploadPhotoMutation,
  useRemovePhotoMutation,
} from '~/hooks/apolloAction'

import {
  setUploadedImages,
  selectUploadedImages,
} from '~/modules/uploadedImages'

import DragSourceImg from '~/byenv/components/sub/DragSourceImg'
import ImageDropZone from '~/byenv/components/sub/ImageDropZone'
import Uploading from '~/byenv/components/sub/Uploading'

import { useSpica } from '~/context'

import { onPaletteSizes } from '~/hooks/toDroppedPositionSizes'
import { baseUrl } from '~/utils/baseUrl'

const PaletteUploadedImages: React.FC = () => {
  const dispatch = useDispatch()
  const uploadedImages = useSelector(selectUploadedImages)
  const onDropSourceToEditorCreator = useOnDropSourceToEditorCreator()

  const [error, setError] = React.useState<string[]>([])

  const { frontToken: token } = useParams() as { frontToken: string }

  const { generalState } = useSpica()

  const [loadingQueue, setLoadingQueue] = React.useState<boolean[]>([])
  const [isDropped, setIsDropped] = React.useState<boolean>(false)

  // 初回
  const { data: fetchData, loading: fetchLoding } = useFetchPhotosQuery({
    variables: { token },
  })
  React.useEffect(() => {
    if (!fetchData?.fetchPhotos) return
    if (uploadedImages?.length > 0) return

    dispatch(setUploadedImages(fetchData?.fetchPhotos?.map((item) => item.url)))
  }, [fetchData])

  // 追加時
  const [uploadPhotoMutation, { data: uploadData }] = useUploadPhotoMutation()
  React.useEffect(() => {
    if (!uploadData?.uploadPhoto) return
    dispatch(
      setUploadedImages(uploadData?.uploadPhoto?.map((item) => item.url))
    )
  }, [uploadData])

  // 削除時
  const [removePhotoMutation, { data: removeData }] = useRemovePhotoMutation()
  React.useEffect(() => {
    if (!removeData?.removePhoto) return
    dispatch(
      setUploadedImages(removeData?.removePhoto?.map((item) => item.url))
    )
  }, [removeData])

  const tokenizeUrl = (src: string) => `${baseUrl}/photos/${token}/${src}`

  // これは非同期でやるので、asyncなonDropが呼ばれる前に実行するように、onDropが実際に
  // 実行されるはずのコンポーネントで呼ぶ
  const addToLoadingQueue = (addingLength: number) => {
    const loadingQueueNext = [...loadingQueue]
    Array(addingLength)
      .fill(true)
      .forEach((bool) => loadingQueueNext.push(bool))
    // loadingQueueNext.push(true)
    setLoadingQueue(loadingQueueNext)
  }
  const removeFromLoadingQueue = () => {
    const loadingQueueNext = [...loadingQueue]
    if (loadingQueueNext.length > 0) loadingQueueNext.shift()
    setLoadingQueue(loadingQueueNext)
  }

  // const [isDroppedJson, setIsDroppedJson] = React.useState<any>({})
  const [isDroppedJson, setIsDroppedJson] = generalState

  const cancelIsDropped = () => setIsDropped(false)

  const validate = (base64: string) => {
    const extension = base64.match(/[^:/]\w+(?=;|,)/)?.shift() + ''
    const size = base64.length * (3 / 4)

    const error: string[] = []
    if (!['jpeg', 'png', 'gif'].includes(extension))
      error.push('画像以外のファイルです')

    // 10MB
    if (size > 10 * 1024 * 1024) error.push('ファイルサイズが大きすぎます')

    return error
  }

  const onDropFileToDropZone = React.useCallback(async (acceptedFiles) => {
    addToLoadingQueue(acceptedFiles.length)
    acceptedFiles.forEach((acceptedFile) => {
      const reader = new FileReader()
      setIsDropped(true)
      reader.onload = async (filerender) => {
        const error = validate(filerender.target?.result as string)

        setError([])

        if (error.length > 0) {
          setError(error)
          removeFromLoadingQueue()
          return
        }

        await uploadPhotoMutation({
          variables: { token, file: filerender.target?.result as string },
        })
        removeFromLoadingQueue()
      }
      reader.readAsDataURL(acceptedFile)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFileToDropZone,
    multiple: false, // デフォはtrue
  })

  const cancelIsDroppedJson = (url) => {
    const jsonNext = { ...isDroppedJson }
    jsonNext[url] = false
    setIsDroppedJson(jsonNext)
  }

  React.useEffect(() => {
    if (!uploadedImages) return

    const json = {}
    uploadedImages.forEach((photoUrl) => {
      json[photoUrl] = !Object.keys(isDroppedJson).includes(photoUrl)
    })
    setIsDroppedJson(json)
  }, [uploadedImages])

  const remove = async (filename: string) => {
    await removePhotoMutation({
      variables: { token, filename },
    })
    // refetchPhoto()
  }

  return (
    <>
      {fetchLoding && <div>loading...</div>}

      <div className="palette">
        {uploadedImages?.map((photoUrl) => (
          <DragSourceImg
            src={tokenizeUrl(photoUrl)}
            remove={() => remove(photoUrl)}
            onDrop={onDropSourceToEditorCreator.image(tokenizeUrl(photoUrl))}
            sizes={onPaletteSizes}
            cancelIsDroppedJson={cancelIsDroppedJson}
            isDropped={isDroppedJson[photoUrl]}
            key={photoUrl}
          />
        ))}
        {loadingQueue.map((_, i) => (
          <div className="drag-source-image-container" key={i}>
            <Uploading key={i} />
          </div>
        ))}
      </div>

      <ImageDropZone
        {...{ getRootProps, getInputProps }}
        addToLoadingQueue={addToLoadingQueue}
      />

      {error.length > 0 ? JSON.stringify(error) : null}
    </>
  )
}

export default PaletteUploadedImages
