import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlmagest, historiesModule } from '@naire-seisakusho/react-almagest'

import { useContextMenu } from '~/hooks/contextMenu'
import { ContextMenuItem } from '~/components/atoms'
import { hasProperties } from '~/utils/hasProperties'

import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const { undo, redo, selectCurrentSvgJson } = historiesModule

type Props = {
  open: boolean
  position: { x: number; y: number }
  targetId: string
  close: () => void
}
const ContextMenu: React.FC<Props> = ({ open, position, targetId, close }) => {
  const dispatch = useDispatch()
  const svgJson = useSelector(selectCurrentSvgJson)

  const { afterCommit } = useAlmagest()
  const { action } = useContextMenu()

  const type = React.useMemo(() => {
    if (!targetId) return 'sight'

    return (
      svgJson?.children?.find(child => child.attributes.id === targetId)
        ?.almagest?.shapeType || 'sight'
    )
  }, [targetId])

  const menu = React.useMemo(() => hasProperties(type).context, [type])

  return (
    <div
      className={switcss({
        designed: '',
        framework: 'mt-2 py-2 w-48 bg-white rounded-lg shadow-xl absolute',
        useFramework: true,
      })}
      style={{
        top: position.y,
        left: position.x,
        display: open ? 'block' : 'none',
      }}
    >
      <ContextMenuItem
        show={menu.includes('undo')}
        callback={() => {
          dispatch(undo({ callback: afterCommit }))
          close()
        }}
      >
        戻る
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('redo')}
        callback={() => {
          dispatch(redo({ callback: afterCommit }))
          close()
        }}
      >
        進む
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('cut')}
        callback={() => action.cut(targetId, close)}
      >
        切り取り
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('copy')}
        callback={() => action.copy(targetId, close)}
      >
        コピー
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('paste')}
        callback={() => action.paste(close)}
      >
        貼り付け
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('remove')}
        callback={() => action.remove(targetId, close)}
      >
        削除
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('fill')}
        callback={() => action.fill(targetId, close)}
      >
        塗りつぶし
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('stroke')}
        callback={() => action.stroke(targetId, close)}
      >
        枠線
      </ContextMenuItem>

      {/* <ContextMenuItem
        show={menu.includes('flipHorizontal')}
        callback={() => action.flipVertical(targetId, close)}
      >
        上下反転
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('flipHorizontal')}
        callback={() => action.flipHorizontal(targetId, close)}
      >
        左右反転
      </ContextMenuItem> */}

      <ContextMenuItem
        show={menu.includes('bringToFront')}
        callback={() => action.bringToFront(targetId, close)}
      >
        最前面へ
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('bringForward')}
        callback={() => action.bringForward(targetId, close)}
      >
        前面へ
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('sendBackward')}
        callback={() => action.sendBackward(targetId, close)}
      >
        背面へ
      </ContextMenuItem>

      <ContextMenuItem
        show={menu.includes('sendToBack')}
        callback={() => action.sendToBack(targetId, close)}
      >
        最背面へ
      </ContextMenuItem>
    </div>
  )
}

export default ContextMenu
