import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { hideAllModalAndOverlay, selectModalState } from '~/modules/modals'

const Modals: React.FC = () => {
  const dispatch = useDispatch()
  const modalState = useSelector(selectModalState)

  return (
    <div
      className={`overlay ${modalState.isShowingOverlay ? '-active' : ''}`}
      data-class="ignoreCancelOnDrop"
      onClick={() => {
        if (modalState.isWaitingResponse) return
        dispatch(hideAllModalAndOverlay())
      }}
    >
      <div
        className={`overlay__spin ${
          modalState.isWaitingResponse ? '-active' : ''
        }`}
      >
        <div className={'loading'}></div>
      </div>
      <p
        className={`overlay__text ${
          modalState.isWaitingResponse ? '-active' : ''
        }`}
      >
        読み込み中
      </p>
    </div>
  )
}

export default Modals
