import * as React from 'react'

type Props = {
  title: string
  mainClassName: string
  isActive: boolean
  content: React.FC<{
    contentClassName: string
  }>
}

const ModalAddOutline: React.FC<Props> = ({
  title,
  mainClassName,
  isActive,
  content,
}) => {
  const Content = content
  return (
    <div className={`modal ${isActive ? '-active' : ''}`}>
      <div className="modal__inner">
        <div className={`modalWindow ${mainClassName}`}>
          <p className="modalWindow__title">{title}</p>
          <Content contentClassName="modalWindow__content" />
        </div>
      </div>
    </div>
  )
}

export default ModalAddOutline
