import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectModalState } from '~/modules/modals'
import queryString from 'query-string'
import { useParams } from 'react-router-dom'

import { Preview } from '~/components/organisms'
import { ModalCloseBtn } from '~/components/atoms'

const ModalConfirm: React.FC = () => {
  const modalState = useSelector(selectModalState)

  const { rt } = queryString.parse(location.search) as { rt: string }

  type Params = {
    frontToken: string
    backToken: string
  }

  const isActive = modalState.modalAttention

  // if (!modalState.modalConfirm) return null

  return (
    <div className="modals">
      <div className={`modal ${isActive ? '-active' : ''}`}>
        <div className="modal__inner">
          <div className="modalWindow">
            <div className="modalWindow__inner">
              <p className="modalWindow__title">制作のご注意</p>
              <div className="modalWindow__main">
                <img
                  src="/img/modalAttention.png"
                  srcSet="/img/modalAttention@2x.png 2x"
                  alt=""
                />
              </div>
              <div className="modalWindow__btns">
                <ModalCloseBtn text="OK" type="primary" size="xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalConfirm
