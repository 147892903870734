import * as React from 'react'

type Props = {
  icon: string
  text: string
  isActive?: boolean
  isDisabled?: boolean
  onClick?: (event: any) => void
}

const EditorMenuBtn: React.FC<Props> = ({
  icon,
  text,
  isActive,
  isDisabled,
  onClick,
  children,
}) => {
  const className = `editorMenuBtn icon-${icon}`

  return (
    <div
      className={`${className} ${isActive ? '-active' : ''} ${
        isDisabled ? '-disabled' : ''
      }`}
      onClick={event => onClick && onClick(event)}
    >
      {text}
      {children}
    </div>
  )
}
export default EditorMenuBtn
