import * as React from 'react'

type Props = {
  isShowingOverlay: boolean
}

const PreviewSideLoading: React.FC<Props> = ({ isShowingOverlay }) => {
  return (
    <div
      className={`preview__side__loading ${isShowingOverlay ? '-active' : ''}`}
    >
      <div
        className={`preview__side__loading__spin ${
          isShowingOverlay ? '-active' : ''
        }`}
      >
        <div className={'loading'}></div>
      </div>
      <p
        className={`preview__side__loading__text ${
          isShowingOverlay ? '-active' : ''
        }`}
      >
        読み込み中
      </p>
    </div>
  )
}

export default PreviewSideLoading
