import { dataset, classList } from './ponyfill'

export const classAccessor = refOrDom => {
  return (...classNames) => {
    const core = doAdding => {
      const mayDom = refOrDom?.current || refOrDom
      if (!mayDom) {
        // とりあえず本番で消えるし出しとこう
        console.log(
          `classAccessor: ${classNames} didn't ${
            doAdding ? 'add' : 'remove'
          } to empty ref/dom.`
        )
        return
      }
      const refClassList = classList(mayDom)
      doAdding
        ? refClassList?.add(...classNames)
        : refClassList?.remove(...classNames)
    }
    // こんな風にoptional-chainの後に[を書いたらあかんらしい
    // classList(ref.current)?[doAdding ? 'add' : 'remove']('-disabled')
    // これはundefindableならあかんらしい
    // (doAdding ? refClassList?.add : refClassList?.remove)(...classNames)
    return {
      add: () => core(true),
      remove: () => core(false),
    }
  }
}
