import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showModalAttention, selectModalState } from '~/modules/modals'
const ShowAttention: React.FC = () => {
  const dispatch = useDispatch()
  const modalState = useSelector(selectModalState)

  return (
    <div className={`showAttention`}>
      <div
        className="showAttention__btn"
        onClick={() => dispatch(showModalAttention())}
      >
        制作のご注意
      </div>
    </div>
  )
}

export default ShowAttention
