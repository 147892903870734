import * as React from 'react'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

type Props = {
  list: { label: string; value: string }[]
  onChange: (value: string) => void
  selected: string
}
const PropertiesSelect: React.FC<Props> = ({ list, onChange, selected }) => {
  if (list.length === 0) null

  return (
    <div className="select -full">
      <select
        className=""
        onChange={event => onChange(event.target.value)}
        value={selected}
      >
        {list.map((item, i) => (
          <option value={item.value} key={i}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default PropertiesSelect
