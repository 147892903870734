import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectModalState } from '~/modules/modals'
import queryString from 'query-string'
import { useParams } from 'react-router-dom'

import { Preview } from '~/components/organisms'
import { ModalCloseBtn } from '~/components/atoms'

const ModalConfirm: React.FC = () => {
  const modalState = useSelector(selectModalState)
  const { frontToken, backToken } = useParams<Params>()

  if (!modalState.modalConfirm) return null

  const { rt } = queryString.parse(location.search) as { rt: string }

  type Params = {
    frontToken: string
    backToken: string
  }

  const cartUrl = rt + encodeURI(`&ct[]=${frontToken}&ct[]=${backToken}`)

  return (
    <div className="modals">
      <div className="modal -active">
        <div className="modal__inner -active">
          <div className={`modalConfirm`}>
            <p className="modalConfirm__title">デザインの確認</p>
            <div className="modalConfirm__content">
              <div className="modalConfirm__preview">
                <Preview />
              </div>
              <p className="modalConfirm__attention">
                うちわは「印刷イメージ」の通り印刷されます。
                <br />
                両面をご確認の上、「注文を続ける」ボタンを押して注文を続けてください。
              </p>

              <div className="modalConfirm__btns">
                <a href={cartUrl} className="btn -conversion -xxl -full">
                  注文を続ける
                </a>
                <ModalCloseBtn />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalConfirm
