import { useSelector, useDispatch } from 'react-redux'
import { draggerModule } from '@naire-seisakusho/react-almagest'
import { setOnDropShape, selectManeuverState } from '~/modules/maneuver'

export const useOnDropShapeLife = () => {
  const dispatch = useDispatch()
  const { setStartable, selectStartable } = draggerModule
  // const startable = useSelector(selectStartable)

  const startOnDropShapeLife = onDropSourceToEditor => {
    dispatch(setOnDropShape(onDropSourceToEditor))
    dispatch(setStartable(false))
  }

  const endOnDropShapeLife = () => {
    dispatch(setOnDropShape(null))
    dispatch(setStartable(true))
  }

  return {
    startOnDropShapeLife,
    endOnDropShapeLife,
  }
}
