export const arrayToJson = <T extends string | number>(
  array: T[],
  reduceFunc: (
    json: { [key in T]: any },
    key: T,
    index: number,
    source: T[]
  ) => any
) =>
  array.reduce((json, key, index, source) => {
    // returnはここでやる
    const returnedJson = reduceFunc(json, key, index, source)
    return returnedJson || json
  }, {} as { [key in T]: any })
