import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectManeuverState,
  setShowingGridGuide,
  setShowingPrintGuide,
  setShowingBoneGuide,
} from '~/modules/maneuver'
import { GuideListItem } from '~/components/molecules'
const GuideList: React.FC = () => {
  const dispatch = useDispatch()
  const maneuverState = useSelector(selectManeuverState)
  return (
    <div className="guideToggle">
      <div className="guideToggle__attention">
        <p className="sideContentAttention">
          印刷ガイド線やグリッド線、うちわ骨の表示・非表示を切り替えることができます。
        </p>
      </div>
      <ul className="guideToggle__list">
        <li>
          <GuideListItem
            guide=""
            text="うちわ骨のイメージ"
            isActive={maneuverState.isShowingBoneGuide}
            onClick={() => {
              dispatch(setShowingBoneGuide(!maneuverState.isShowingBoneGuide))
            }}
          />
        </li>
        <li>
          <GuideListItem
            guide=""
            text="グリッド線"
            isActive={maneuverState.isShowingGridGuide}
            onClick={() => {
              dispatch(setShowingGridGuide(!maneuverState.isShowingGridGuide))
            }}
          />
        </li>
        <li>
          <GuideListItem
            guide=""
            text="印刷ガイド"
            isActive={maneuverState.isShowingPrintGuide}
            onClick={() => {
              dispatch(setShowingPrintGuide(!maneuverState.isShowingPrintGuide))
            }}
          />
        </li>
      </ul>
    </div>
  )
}
export default GuideList
