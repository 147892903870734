import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash-es'
import {
  useAlmagest,
  historiesModule,
  targetModule,
  ShapeTypeSvgValues,
} from '@naire-seisakusho/react-almagest'

import { selectClipboard, setClipboard } from '~/modules/shapes'

const { addElement, removeElement, add: addHistory } = historiesModule
const { setId, clear, selectTargetId } = targetModule

export const useUpdateDocument = () => {
  const dispatch = useDispatch()
  const clipboard = useSelector(selectClipboard)
  const targetId = useSelector(selectTargetId)

  const { almagest, svgJson, afterCommit } = useAlmagest() as any

  const findElement = (id: string) =>
    svgJson.children.find((child) => child.attributes.id === id)

  const add = (element) => {
    dispatch(addElement({ element, callback: afterCommit }))
  }

  const copy = (id: string) => {
    const element = findElement(id)
    if (!element) return

    dispatch(setClipboard(element))
  }

  const paste = () => {
    if (!clipboard) return

    const newElement = cloneDeep(clipboard)

    const originalShapeId = newElement?.attributes?.id
    newElement.almagest.originalShapeId = originalShapeId
    newElement.almagest.useAnimateSpawn = false

    // idが衝突するから消す
    delete newElement?.attributes?.id

    // 追加前にattributeの計算が必要
    const shapeId = 'tmp'

    const prev =
      cloneDeep(
        svgJson.children
          .filter((child) => child.almagest.originalShapeId === originalShapeId)
          .pop()
      ) || newElement
    const { shapeType, positionsCurrent } = prev.almagest

    const angle = 0

    const create = ShapeTypeSvgValues.includes(shapeType)
      ? almagest.create(shapeId).svg
      : almagest.create(shapeId)[shapeType]

    const positionsInit = { ...positionsCurrent }
    positionsCurrent.srcX += 20
    positionsCurrent.srcY += 20
    positionsCurrent.dstX += 20
    positionsCurrent.dstY += 20

    newElement.almagest.positionsCurrent = positionsCurrent
    newElement.almagest.positionsInit = positionsInit

    const shape = create(positionsCurrent, positionsInit, angle)

    // FIXME: もう少し気の利いた位置に設置したい
    if (shape.positions.shape.srcY > almagest.sight.height) {
      shape.positions.shape.srcY = positionsInit.srcY
      shape.positions.shape.dstY = positionsInit.dstY
    }
    if (shape.positions.shape.srcX > almagest.sight.width) {
      shape.positions.shape.srcX = positionsInit.srcX
      shape.positions.shape.dstX = positionsInit.dstX
    }

    newElement.attributes = {
      ...newElement.attributes,
      ...shape.observerReturnValues.shape().attributes,
    }

    delete almagest.shapes.tmp
    dispatch(
      addElement({ element: cloneDeep(newElement), callback: afterCommit })
    )
  }

  const clone = (id: string) => {
    const newElement = cloneDeep(findElement(id))

    if (!newElement) return

    const originalShapeId = newElement?.attributes?.id
    newElement.almagest.originalShapeId = originalShapeId
    newElement.almagest.useAnimateSpawn = false

    delete newElement?.attributes?.id

    // 追加前にattributeの計算が必要
    const shapeId = 'tmp'
    const { shapeType, positionsCurrent } = newElement.almagest
    const angle = 0

    const create = ShapeTypeSvgValues.includes(shapeType)
      ? almagest.create(shapeId).svg
      : almagest.create(shapeId)[shapeType]

    const positionsInit = { ...positionsCurrent }
    positionsCurrent.srcX += 20
    positionsCurrent.srcY += 20
    positionsCurrent.dstX += 20
    positionsCurrent.dstY += 20

    const shape = create(positionsCurrent, positionsInit, angle)

    // FIXME: もう少し気の利いた位置に設置したい
    if (shape.positions.shape.srcY > almagest.sight.height) {
      shape.positions.shape.srcY = positionsInit.srcY
      shape.positions.shape.dstY = positionsInit.dstY
    }
    if (shape.positions.shape.srcX > almagest.sight.width) {
      shape.positions.shape.srcX = positionsInit.srcX
      shape.positions.shape.dstX = positionsInit.dstX
    }

    newElement.attributes = {
      ...newElement.attributes,
      ...shape.observerReturnValues.shape().attributes,
    }

    delete almagest.shapes.tmp
    dispatch(
      addElement({ element: cloneDeep(newElement), callback: afterCommit })
    )
  }

  const cut = (id: string) => {
    const element = findElement(id)
    if (!element) return

    dispatch(setClipboard(element))
    dispatch(removeElement({ id, callback: afterCommit }))
  }

  const remove = (id: string) => {
    const element = findElement(id)
    if (!element) return

    dispatch(removeElement({ id, callback: afterCommit }))
  }

  const replace = (id: string, element: any) => {
    const cloneSvgJson = cloneDeep(svgJson)

    cloneSvgJson.children = cloneSvgJson.children.map((child) => {
      return child.attributes.id === id ? element : child
    })

    dispatch(
      addHistory({
        document: cloneSvgJson,
        target: id,
        callback: afterCommit,
      })
    )
  }

  // 最前面へ
  const bringToFront = (id: string) => {
    const cloneSvgJson = cloneDeep(svgJson)
    const index = cloneSvgJson.children.findIndex(
      (child) => child.attributes.id === id
    )

    if (index === -1) return

    const element = cloneSvgJson.children.splice(index, 1)[0]
    cloneSvgJson.children.push(element)

    dispatch(clear())
    dispatch(
      addHistory({ document: cloneSvgJson, target: id, callback: afterCommit })
    )
    // FIXME: 最終手段 setTimeout 0
    setTimeout(() => dispatch(setId(targetId)), 0)
  }

  // 前面へ
  const bringForward = (id: string) => {
    const cloneSvgJson = cloneDeep(svgJson)
    const index = cloneSvgJson.children.findIndex(
      (child) => child.attributes.id === id
    )

    if (index === -1) return

    const element = cloneSvgJson.children.splice(index, 1)[0]
    cloneSvgJson.children.splice(index + 1, 0, element)

    dispatch(clear())
    dispatch(
      addHistory({ document: cloneSvgJson, target: id, callback: afterCommit })
    )
    // FIXME: 最終手段 setTimeout 0
    setTimeout(() => dispatch(setId(targetId)), 0)
  }

  // 背面へ
  const sendBackward = (id: string) => {
    const cloneSvgJson = cloneDeep(svgJson)
    const index = cloneSvgJson.children.findIndex(
      (child) => child.attributes.id === id
    )

    if (index < 1) return

    const element = cloneSvgJson.children.splice(index, 1)[0]
    cloneSvgJson.children.splice(index - 1, 0, element)

    dispatch(clear())
    dispatch(
      addHistory({ document: cloneSvgJson, target: id, callback: afterCommit })
    )
    // FIXME: 最終手段 setTimeout 0
    setTimeout(() => dispatch(setId(targetId)), 0)
  }

  // 最背面へ
  const sendToBack = (id: string) => {
    const cloneSvgJson = cloneDeep(svgJson)
    const index = cloneSvgJson.children.findIndex(
      (child) => child.attributes.id === id
    )

    if (index < 1) return

    const element = cloneSvgJson.children.splice(index, 1)[0]
    cloneSvgJson.children.unshift(element)

    dispatch(clear())
    dispatch(
      addHistory({ document: cloneSvgJson, target: id, callback: afterCommit })
    )
    // FIXME: 最終手段 setTimeout 0
    setTimeout(() => dispatch(setId(targetId)), 0)
  }

  return {
    add,
    copy,
    paste,
    clone,
    cut,
    remove,
    replace,
    bringToFront,
    bringForward,
    sendBackward,
    sendToBack,
  }
}
