import * as React from 'react'

// type Props = Pick<
//   ReturnType<typeof useDropzone>,
//   'getRootProps' | 'getInputProps'
// >

import { classAccessor } from '~/utils/classAccessor'

type dragOver = () => void

// 一旦anyに逃げたのであとヨロです
const ImageDropZone: React.FC<any> = ({
  getRootProps,
  getInputProps,
  addToLoadingQueue,
}) => {
  /*
  とりあえず再ラップ必要なものだけ上書きする事にした(バージョンによる改廃とかアリエルし)
  rootProps:
    onKeyDown: ƒ (event)
    onFocus: ƒ (event)
    onBlur: ƒ (event)
    onClick: ƒ (event)
    onDragEnter: ƒ (event)
    onDragOver: ƒ (event)
    onDragLeave: ƒ (event)
    onDrop: ƒ (event)
    ref: {current: div.upload-image-droppable}
    tabIndex: 0

  inputProps:
    accept: undefined
    multiple: true
    type: "file"
    style: {display: "none"}
    onChange: ƒ (event)
    onClick: ƒ (event)
    autoComplete: "off"
    tabIndex: -1
    ref: {current: input}
  */
  const wrap = (sourcePropsFunc, callback) => {
    const sourceProps = sourcePropsFunc() as any
    const wrappedProps = { ...sourceProps }
    callback(sourceProps, wrappedProps)
    return () => wrappedProps
  }

  const getRootPropsWrapped = wrap(getRootProps, (source, wrapped) => {
    const { ref: div } = wrapped

    const clacc = classAccessor(div)('drag-over')

    wrapped.onDragOver = (event) => {
      source?.onDragOver(event)
      clacc.add()
    }
    wrapped.onDragLeave = (event) => {
      source?.onDragLeave(event)
      clacc.remove()
    }
    wrapped.onDrop = (event) => {
      // addToLoadingQueue()
      source?.onDrop(event)
      clacc.remove()
    }
  })

  const getInputPropsWrapped = wrap(getInputProps, (source, wrapped) => {
    wrapped.onChange = (event) => {
      // addToLoadingQueue()
      source?.onChange(event)
    }
  })

  return (
    <>
      <input {...getInputPropsWrapped()} />

      <div
        ref={getRootPropsWrapped().ref}
        {...getRootPropsWrapped()}
        className="upload-image"
      >
        <p>アップロード</p>
      </div>
    </>
  )
}

export default ImageDropZone
