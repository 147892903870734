import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useAlmagest } from '@naire-seisakusho/react-almagest'

export type Operation = 'undo' | 'redo' | 'openContextMenu'

export const useLogger = () => {
  const { svgJson } = useAlmagest()
  const { token } = useParams()

  const writeLog = ({ action }: { action: Operation }) => {
    console.log('log', {
      token,
      operation: action,
      document: svgJson,
    })
  }

  return {
    writeLog,
  }
}
