import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectModalState } from '~/modules/modals'

import {
  ModalEditText,
  ModalAddDrawingShape,
  ModalAddDrawingLine,
  ModalAddMaterial,
} from '~/components/organisms'

const Modals: React.FC = () => {
  const modalState = useSelector(selectModalState)

  return (
    <div className="modals" data-class="ignoreCancelOnDrop">
      <ModalEditText isActive={modalState.modalEditText} />
      <ModalAddDrawingShape isActive={modalState.modalAddDrawingShape} />
      <ModalAddDrawingLine isActive={modalState.modalAddDrawingLine} />
      <ModalAddMaterial isActive={modalState.modalAddMaterial} />
    </div>
  )
}

export default Modals
