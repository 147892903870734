import ApolloClient from 'apollo-boost'
import 'isomorphic-unfetch'

export const client = new ApolloClient({
  fetchOptions: { fetch },
  uri:
    process.env.HOST && process.env.PORT
      ? `//${process.env.HOST}:${process.env.PORT}/graphql`
      : '/graphql',
})
