import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  // もうちょっとstore対象集まってきたら名前つけ直したい
  name: 'doingHotKeyTasks',
  initialState: {
    remove: false,
    undo: false,
    redo: false,
    copy: false,
    cut: false,
    paste: false,
  },
  reducers: {
    // is/setはJava命名に倣った
    setDoingHotKeyTaskRemove: (state, action) => {
      state.remove = action.payload
    },
    setDoingHotKeyTaskUndo: (state, action) => {
      state.undo = action.payload
    },
    setDoingHotKeyTaskRedo: (state, action) => {
      state.redo = action.payload
    },
    setDoingHotKeyTaskCopy: (state, action) => {
      state.copy = action.payload
    },
    setDoingHotKeyTaskCut: (state, action) => {
      state.cut = action.payload
    },
    setDoingHotKeyTaskPaste: (state, action) => {
      state.paste = action.payload
    },
  },
})

export const {
  setDoingHotKeyTaskRemove,
  setDoingHotKeyTaskUndo,
  setDoingHotKeyTaskRedo,
  setDoingHotKeyTaskCopy,
  setDoingHotKeyTaskCut,
  setDoingHotKeyTaskPaste,
} = slice.actions

export const selectDoingHotKeyTasksState = state => state.doingHotKeyTasks

export default slice.reducer
