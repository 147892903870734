import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  // もうちょっとstore対象集まってきたら名前つけ直したい
  name: 'maneuver',
  initialState: {
    isMacCtrlToggleOn: false,
    isShowingColorPicker: false,
    isShowingGridGuide: true,
    isShowingPrintGuide: true,
    isShowingBoneGuide: true,
    scrollTop: 0,
    onDropShape: ({ x, y }: { x: number; y: number }): void => {},
    onSpawnShape: false,
  },
  reducers: {
    // is/setはJava命名に倣った
    setMacCtrlToggleOn: (state, action) => {
      state.isMacCtrlToggleOn = action.payload
    },
    setShowingColorPicker: (state, action) => {
      state.isShowingColorPicker = action.payload
    },
    setShowingGridGuide: (state, action) => {
      state.isShowingGridGuide = action.payload
    },
    setShowingPrintGuide: (state, action) => {
      state.isShowingPrintGuide = action.payload
    },
    setShowingBoneGuide: (state, action) => {
      state.isShowingBoneGuide = action.payload
    },
    setScrollTop: (state, action) => {
      state.scrollTop = action.payload
    },
    setOnDropShape: (state, action) => {
      state.onDropShape = action.payload
    },
    setOnSpawnShape: (state, action) => {
      state.onSpawnShape = action.payload
    },
  },
})

export const {
  setMacCtrlToggleOn,
  setShowingColorPicker,
  setShowingGridGuide,
  setShowingPrintGuide,
  setShowingBoneGuide,
  setScrollTop,
  setOnDropShape,
  setOnSpawnShape,
} = slice.actions

export const selectManeuverState = state => state.maneuver

export default slice.reducer
