import * as React from 'react'
import { useSpica } from '~/context'
import { useAlmagest } from '@naire-seisakusho/react-almagest'

const uchiwaSizes = {
  reg: 'regular',
  com: 'compact',
  mid: 'middle',
}
type Props = {
  isActive: boolean
}

const BoneGuide: React.FC<Props> = ({ isActive }) => {
  const { almagest } = useAlmagest()
  const { uchiwaSizeState } = useSpica()
  const [uchiwaSize] = uchiwaSizeState
  if (!almagest?.sight || !uchiwaSize) return null
  const { width, height } = almagest?.sight
  return (
    <div className={`boneGuide ${isActive ? '-active' : ''}`}>
      <div
        className={`boneGuide__bone -${uchiwaSizes[uchiwaSize]}`}
        style={{ width: width, height: height }}
      ></div>
    </div>
  )
}
export default BoneGuide
