import * as React from 'react'
import { ToggleDisplayBtn } from '~/components/atoms'
type Props = {
  guide: string
  text: string
  isActive?: boolean
  onClick?: (event: any) => void
}

const GuideListItem: React.FC<Props> = ({ guide, text, isActive, onClick }) => {
  return (
    <div
      className={`guideListItem ${isActive ? '-active' : ''}`}
      onClick={event => onClick && onClick(event)}
    >
      <div className="guideListItem__icon"></div>
      <img src={``} alt=""/>
      {text}
    </div>
  )
}
export default GuideListItem