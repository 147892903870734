import * as React from 'react'
import svgson from 'svgson'
import { switcss } from '~/utils/switcss'

import {
  ShapeTypes,
  ShapeTypeOriginSizesToAttrbutes,
} from '@naire-seisakusho/react-almagest'

/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

type Props = {
  svgAttributes: Attributes
  item: any
  guide: Element
  index: number
  active: boolean
  onClick: (event: any) => void
}
const Layer: React.FC<Props> = ({
  svgAttributes,
  item,
  guide,
  active,
  onClick,
}) => {
  const { shapeType } = item.almagest

  const label = {
    [ShapeTypes.circle]: () => '真円',
    [ShapeTypes.ellipse]: () => '楕円',
    [ShapeTypes.image]: () => '画像',
    [ShapeTypes.line]: () => '線',
    [ShapeTypes.preset]: () => '素材',
    [ShapeTypes.rect]: () => '矩形',
    [ShapeTypes.square]: () => '正方形',
    [ShapeTypes.triangleEquilateral]: () => '正三角形',
    [ShapeTypes.triangleIsosceles]: () => '二等辺三角形',
    [ShapeTypes.triangleRightLeft]: () => '直角三角形(左直角)',
    [ShapeTypes.triangleRightRight]: () => '直角三角形(右直角)',
    [ShapeTypes.text]: () => item.almagest.fontOption.body,
  }[shapeType]()

  return (
    <div
      className={`layerItem ${active ? '-active' : ''}`}
      onClick={event => onClick(event)}
    >
      <div
        className={switcss({
          designed: 'layerItem__thumbnail',
          framework: 'w-3/12',
          useFramework: false,
        })}
      >
        <div className="svgThumbnail">
          <svg viewBox={svgAttributes?.viewBox + ''}>
            <g
              dangerouslySetInnerHTML={{
                __html: item ? (svgson as any).stringify(item) : '',
              }}
            />
            <g
              dangerouslySetInnerHTML={{
                __html: guide ? (svgson as any).stringify(guide) : '',
              }}
            />
          </svg>
        </div>
      </div>
      <p className="layerItem__name">{label}</p>
    </div>
  )
}

export default Layer
