import { createSlice } from '@reduxjs/toolkit'

// 動的生成だとreducerがpayload引数を必要としない事を分かってくれなくてTSエラー出る
// のでやめた
const modalKeys = [
  'modalEditText',
  'modalAddDrawingShape',
  'modalAddDrawingLine',
  'modalAddMaterial',
  'modalAttention',
  'modalConfirm',
]

const setFalseToAllModal = state =>
  modalKeys.forEach(key => (state[key] = false))

const setModalAndOverlay = (state, isShowing) => {
  state.isShowingModal = isShowing
  state.isShowingOverlay = isShowing
}

export const slice = createSlice({
  name: 'modals',
  initialState: {
    isShowingModal: false,
    isShowingOverlay: true,
    isWaitingResponse: false,
    modalEditText: false,
    modalAddDrawingShape: false,
    modalAddDrawingLine: false,
    modalAddMaterial: false,
    modalConfirm: false,
    modalAttention: true,
    textAction: 'add',
  },
  reducers: {
    hideAllModalAndOverlay: state => {
      setFalseToAllModal(state)
      setModalAndOverlay(state, false)
    },
    hideAllModal: state => {
      setFalseToAllModal(state)
      state.isShowingModal = false
    },
    showOverlay: state => {
      state.isShowingOverlay = true
    },
    hideOverlay: state => {
      state.isShowingOverlay = false
    },
    startWaitingResponse: state => {
      state.isWaitingResponse = true
    },
    endWaitingResponse: state => {
      state.isWaitingResponse = false
    },
    showModalAddText: state => {
      state.modalEditText = true
      state.textAction = 'add'
      setModalAndOverlay(state, true)
    },
    showModalChangeText: state => {
      state.modalEditText = true
      state.textAction = 'change'
      setModalAndOverlay(state, true)
    },
    showModalAddDrawingShape: state => {
      state.modalAddDrawingShape = true
      setModalAndOverlay(state, true)
    },
    showModalAddDrawingLine: state => {
      state.modalAddDrawingLine = true
      setModalAndOverlay(state, true)
    },
    showModalAddMaterial: state => {
      state.modalAddMaterial = true
      setModalAndOverlay(state, true)
    },
    showModalConfirm: state => {
      state.modalConfirm = true
      setModalAndOverlay(state, true)
    },
    showModalAttention: state => {
      state.modalAttention = true
      setModalAndOverlay(state, true)
    },
  },
})

export const {
  hideAllModalAndOverlay,
  hideAllModal,
  showOverlay,
  hideOverlay,
  startWaitingResponse,
  endWaitingResponse,
  showModalAddText,
  showModalChangeText,
  showModalAddDrawingShape,
  showModalAddDrawingLine,
  showModalAddMaterial,
  showModalConfirm,
  showModalAttention,
} = slice.actions

export const selectModalState = state => state.modals
export const selectTextAction = state => state.modals.textAction

export default slice.reducer
