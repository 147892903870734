import { MaterialTypes } from '~/dedicated/materialTypes'
import { byenv } from '~/byenv'
const {
  dedicated: { alertWhenHasUrlNotFoundOnIntra, addMaterialListOnDev },
} = byenv

export type Material = {
  type: string
  name: string
  url: {
    thumbnail: string
    image: string
  }
}

export type MaterialList = Material[]

const basisList: MaterialList = [
  {
    type: MaterialTypes.backgroundImage,
    name: '幾何1',
    url: {
      thumbnail: '/materials/background/thumbnail/abstract1_regular.jpg',
      image: '/materials/background/abstract1_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '幾何2',
    url: {
      thumbnail: '/materials/background/thumbnail/abstract2_regular.jpg',
      image: '/materials/background/abstract2_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '幾何3',
    url: {
      thumbnail: '/materials/background/thumbnail/abstract3_regular.jpg',
      image: '/materials/background/abstract3_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '幾何4',
    url: {
      thumbnail: '/materials/background/thumbnail/abstract4_regular.jpg',
      image: '/materials/background/abstract4_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '幾何5',
    url: {
      thumbnail: '/materials/background/thumbnail/abstract5_regular.jpg',
      image: '/materials/background/abstract5_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '朝顔2',
    url: {
      thumbnail: '/materials/background/thumbnail/asagao2_regular.jpg',
      image: '/materials/background/asagao2_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '麻の葉',
    url: {
      thumbnail: '/materials/background/thumbnail/asanoha_regular.jpg',
      image: '/materials/background/asanoha_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '野球',
    url: {
      thumbnail: '/materials/background/thumbnail/baseball_regular.jpg',
      image: '/materials/background/baseball_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: 'ビーチ1',
    url: {
      thumbnail: '/materials/background/thumbnail/beach1_regular.jpg',
      image: '/materials/background/beach1_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: 'ビーチ2',
    url: {
      thumbnail: '/materials/background/thumbnail/beach2_regular.jpg',
      image: '/materials/background/beach2_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: 'ビール',
    url: {
      thumbnail: '/materials/background/thumbnail/beer_regular.jpg',
      image: '/materials/background/beer_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '青海波1',
    url: {
      thumbnail: '/materials/background/thumbnail/bluewave1_regular.jpg',
      image: '/materials/background/bluewave1_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '青海波2',
    url: {
      thumbnail: '/materials/background/thumbnail/bluewave2_regular.jpg',
      image: '/materials/background/bluewave2_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '盆踊り1',
    url: {
      thumbnail: '/materials/background/thumbnail/bonodori1_regular.jpg',
      image: '/materials/background/bonodori1_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '盆踊り2',
    url: {
      thumbnail: '/materials/background/thumbnail/bonodori2_regular.jpg',
      image: '/materials/background/bonodori2_regular.png',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: '盆踊り3',
    url: {
      thumbnail: '/materials/background/thumbnail/bonodori3_regular.jpg',
      image: '/materials/background/bonodori3_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: 'キャンペーン1',
    url: {
      thumbnail: '/materials/background/thumbnail/campaign1_regular.jpg',
      image: '/materials/background/campaign1_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: 'キャンペーン2',
    url: {
      thumbnail: '/materials/background/thumbnail/campaign2_regular.jpg',
      image: '/materials/background/campaign2_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: 'キャンペーン3',
    url: {
      thumbnail: '/materials/background/thumbnail/campaign3_regular.jpg',
      image: '/materials/background/campaign3_regular.jpg',
    },
  },
  {
    type: MaterialTypes.backgroundImage,
    name: 'キャンペーン4',
    url: {
      thumbnail: '/materials/background/thumbnail/campaign4_regular.jpg',
      image: '/materials/background/campaign4_regular.jpg',
    },
  },
]

// 不存在URLアラート
alertWhenHasUrlNotFoundOnIntra(basisList)

// isDevelopmentの時だけあとで先頭にSVG追加してます
addMaterialListOnDev(basisList)

export const materialList = basisList
