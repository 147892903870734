import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectManeuverState } from '~/modules/maneuver'
import {
  uchiwaSizeQueryStringValueDefault,
  uchiwaSizeQueryStringValueByLogicals,
  uchiwaSizeStrangeToQueryStringValue,
} from '~/utils/uchiwaSizeIsolater'

import { useSpica } from '~/context'

const toUrlInitializing = (uchiwaSizeQueryStringValue) =>
  `/?l=${uchiwaSizeQueryStringValue}&rt=https%3A%2F%2Fuchiwa.lestas.jp%2Fcarts%2Fnew_item_simulator%3Fproduct_id%3D2873%26volume%3D1000%26uchiwa_bone_color%3Dwhite%26uchiwa_package_type%3Dnone`

const IntraController: React.FC = () => {
  const maneuverState = useSelector(selectManeuverState)
  const { uchiwaSizeState } = useSpica()
  const [uchiwaSize] = uchiwaSizeState

  const uchiwaSizeSelectRef = React.useRef<HTMLSelectElement>(null)

  if (uchiwaSizeSelectRef.current?.value)
    uchiwaSizeSelectRef.current.value =
      uchiwaSizeStrangeToQueryStringValue(uchiwaSize) ||
      uchiwaSizeQueryStringValueDefault

  const initialize = () => {
    localStorage.removeItem('persist:spica')
    location.href = toUrlInitializing(uchiwaSizeSelectRef?.current?.value)
  }

  const formatDate = (date, format) => {
    const weekdays = ['日', '月', '火', '水', '木', '金', '土']
    if (!format) {
      format = 'YYYY/MM/DD(WW) hh:mm:dd'
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const weekday = weekdays[date.getDay()]
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const secounds = date.getSeconds()

    const pad = (num) => num.toString().padStart(2, '0')

    const replacements = {
      YYYY: year,
      Y: year,
      MM: pad(month),
      M: month,
      DD: pad(day),
      D: day,
      WW: weekday,
      hh: pad(hours),
      h: hours,
      mm: pad(minutes),
      m: minutes,
      ss: pad(secounds),
      s: secounds,
    }

    const regExp = new RegExp(`(${Object.keys(replacements).join('|')})`, 'g')

    return format.replace(regExp, (matched) => {
      return replacements[matched]
    })
  }

  const checkSentry = () => {
    const date = formatDate(
      new Date(),
      'Y年MM月DD日(WW) hh時mm分ss秒 地球が約46億回回った時'
    )

    const host = window.location.host
    const title = `${host}\n${date}`
    alert(
      `${title}\nSentryチェック用にエラーを発生させます\n開発者ツールのコンソールにもエラーが表示されます`
    )
    throw new Error(
      `${title}\nSentryにこのExceptionが届くかも知れないし届かないかも知れない`
    )
  }

  return (
    <nav className="intraPanel">
      <div className="title">社内検証用パネル</div>
      <div className="content">
        <div className="selectSet">
          <label className="label">
            <span>うちわサイズを</span>
            <span>変更して初期化</span>
          </label>
          <select
            ref={uchiwaSizeSelectRef}
            className="select"
            onChange={initialize}
          >
            {Object.keys(uchiwaSizeQueryStringValueByLogicals).map((key) => {
              return (
                <option
                  key={key}
                  value={uchiwaSizeQueryStringValueByLogicals[key]}
                >
                  {key}
                </option>
              )
            })}
          </select>
        </div>
        <button className="button" onClick={initialize}>
          初期化
        </button>
        <button className="button" onClick={checkSentry}>
          Sentry
        </button>
        <div
          className={`using-browser-contextmenu ${
            maneuverState.isMacCtrlToggleOn ? 'showing' : ''
          }`}
        >
          (社内用)右クリックはブラウザのメニュー
        </div>
      </div>
    </nav>
  )
}
export default IntraController
