import * as React from 'react'
import svgson from 'svgson'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const ContextMenuItem: React.FC<{ svg: Element }> = ({ svg }) => {
  return (
    <div
      className="svgThumbnail"
      dangerouslySetInnerHTML={{
        __html: (svgson as any).stringify(svg),
      }}
    />
  )
}

export default ContextMenuItem
