import React from 'react'
import { useDragOriginal } from '~/byenv/hooks/dragOriginal'

import { Loading } from '~/byenv/components/sub'

import { classAccessor } from '~/utils/classAccessor'

type Props = {
  src: string
  onDrop: any
  remove?: () => Promise<void>
  cancelIsDroppedJson?: (url: any) => void
  isDropped: boolean
  sizes: { width: number; height: number }
}

const DragSourceImg: React.FC<Props> = ({
  src,
  onDrop,
  remove,
  cancelIsDroppedJson,
  isDropped,
  sizes,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  // ほんとはサイズをちゃんとセットしたいのでrefっとく
  const overlay = React.useRef<HTMLDivElement>(null)

  // const [{ opacity, cursor }, drag] = useDragCommon(ref, createElement)
  const [{ opacity, cursor }, dragSourceRef] = useDragOriginal(onDrop)

  const [isShow, setIsShow] = React.useState<boolean>(false)
  const loaded = () => setIsShow(true)

  const clacc = classAccessor(ref)
  const claccHoverRemove = clacc('hover-remove')
  const claccRemoving = clacc('removing')

  const mouseOverOnRemove = () => claccHoverRemove.add()
  const mouseLeaveOnRemove = () => claccHoverRemove.remove()

  const doRemove = () => {
    claccRemoving.add()
    setTimeout(async () => remove && (await remove()), 500)
  }

  return (
    <div
      ref={ref}
      className={`drag-source-image-container ${isDropped ? 'dropped' : ''}`}
    >
      {!isShow && (
        <>
          <Loading />
          <img
            src={src}
            style={{
              display: 'none',
            }}
            onLoad={loaded}
          />
        </>
      )}
      <div ref={overlay} className="removing-overlay"></div>
      <img
        ref={dragSourceRef}
        src={src}
        className="drag-source drag-source-image"
        style={{
          maxWidth: sizes.width,
          maxHeight: sizes.height,
          opacity,
          cursor,
          display: isShow ? '' : 'none',
        }}
        onLoad={cancelIsDroppedJson}
      />
      {remove && (
        <button
          className="remove"
          onMouseOver={mouseOverOnRemove}
          onMouseLeave={mouseLeaveOnRemove}
          onClick={doRemove}
          style={{ display: isShow ? '' : 'none' }}
        >
          削除
        </button>
      )}
    </div>
  )
}

export default DragSourceImg

// memo化で再レンダー防げるけどstate変更を検知できなくなる
// export default React.memo(DragSourceImg, (prev, next) => prev.src === next.src)
