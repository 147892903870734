import * as React from 'react'
type Props = {
  isActive?: boolean
  onClick?: (event: any) => void
}

const ToggleDisplayBtn: React.FC<Props> = ({isActive, onClick }) => {

  return (
    <div
      className={`toggleDisplayBtn ${isActive ? 'active' : ''}`}
      onClick={event => onClick && onClick(event)}
    >
    </div>
  )
}
export default ToggleDisplayBtn
