import { useSelector } from 'react-redux'

import { inputDeviceModule } from '@naire-seisakusho/react-almagest'

import { useEventListener } from '~/hooks/eventListener'
import { useUpdateDocument } from '~/hooks/updateDocument'

import { selectManeuverState } from '~/modules/maneuver'

import { usingOriginalContextMenu } from '~/utils/devConstants'

import { byenv } from '~/byenv/'
const {
  utils: { isUsingBrowserContextMenu },
} = byenv

const { selectKey } = inputDeviceModule

export const useContextMenu = () => {
  const keyStatus = useSelector(selectKey)
  const updateDocument = useUpdateDocument()
  const maneuverState = useSelector(selectManeuverState)

  // ブラウザのコンテクストメニューを無効化
  if (usingOriginalContextMenu)
    useEventListener<MouseEvent>('contextmenu', event => {
      if (isUsingBrowserContextMenu(maneuverState.isMacCtrlToggleOn)) return
      event.preventDefault()
    })

  type Path = number[]
  type Callback = () => void

  const action = {
    cut: (id: string, callback: Callback) => {
      updateDocument.cut(id)
      callback()
    },
    copy: (id: string, callback: Callback) => {
      updateDocument.copy(id)
      callback()
    },
    paste: (callback: Callback) => {
      updateDocument.paste()
      callback()
    },
    remove: (id: string, callback: Callback) => {
      updateDocument.remove(id)
      callback()
    },
    fill: (id: string, callback: Callback) => {
      console.log(id, callback)
    },
    stroke: (id: string, callback: Callback) => {
      console.log(id, callback)
    },
    flipVertical: (id: string, callback: Callback) => {
      console.log(id, callback)
    },
    flipHorizontal: (id: string, callback: Callback) => {
      console.log(id, callback)
    },
    bringToFront: (id: string, callback: Callback) => {
      updateDocument.bringToFront(id)
      callback()
    },
    bringForward: (id: string, callback: Callback) => {
      updateDocument.bringForward(id)
      callback()
    },
    sendBackward: (id: string, callback: Callback) => {
      updateDocument.sendBackward(id)
      callback()
    },
    sendToBack: (id: string, callback: Callback) => {
      updateDocument.sendToBack(id)
      callback()
    },
  }

  return {
    action,
  }
}
