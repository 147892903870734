import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from '~/modules'
import { SpicaProvider } from '~/context'
import { client } from '~/lib/apollo'
import Routes from '~/routes'

import '~/tailwind/main.css'
import '~/scss/main.scss'

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SpicaProvider>
          <Routes />
        </SpicaProvider>
      </PersistGate>
    </Provider>
  </ApolloProvider>
)

export default App
