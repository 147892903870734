import React from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { useCreateTokenMutation } from '~/hooks/apolloAction'
import {
  uchiwaSizeStrangeDefault,
  uchiwaSizeStrangeByQueryStringValues,
} from '~/utils/uchiwaSizeIsolater'

const Guard = () => {
  const { l, rt } = queryString.parse(location.search) as {
    l: string
    rt: string
  }
  const history = useHistory()

  // TODO: 指定なければ uchiwaSizeDefault にする
  const [createTokenMutation, { data }] = useCreateTokenMutation({
    variables: {
      typeId: (uchiwaSizeStrangeByQueryStringValues[l] ||
        uchiwaSizeStrangeDefault) as string,
    },
  })

  React.useEffect(() => {
    createTokenMutation()
  }, [])

  React.useEffect(() => {
    const res = data?.createToken
    if (!res) return

    history.push(
      `/${res.frontToken}/${res.backToken}/front?rt=${encodeURIComponent(rt)}`
    )
  }, [data])

  return null
}

export default Guard
