import React from 'react'

import { Header, Preview, ConfirmFooter } from '~/components/organisms'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const ConfirmTemplate: React.FC = () => {
  return (
    <div
      className={switcss({
        designed: '',
        framework: 'h-screen',
        useFramework: true,
      })}
    >
      <Header />
      <div
        className={switcss({
          designed: '',
          framework:
            'flex h-full flex justify-center content-center flex-wrap bg-gray-200',
          useFramework: true,
        })}
      >
        <Preview />
      </div>
      <ConfirmFooter />
    </div>
  )
}

export default ConfirmTemplate
