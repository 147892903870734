import * as React from 'react'
import { PreviewSideLoading } from '~/components/molecules'

type Props = {
  uchiwaSize: string | undefined
  sideName: string
  historyId: string
  tokenizedFileName: string | undefined
  isWaitingSaveData: boolean
}

const PreviewSide: React.FC<Props> = ({
  uchiwaSize,
  sideName,
  historyId,
  tokenizedFileName,
  isWaitingSaveData,
}) => {
  const [isShowingOverlay, setShowingOverlay] = React.useState<boolean>(true)

  return (
    <div className="preview__side">
      <div className="preview__side__images">
        <img
          className={`preview__side__images__skeleton ${
            isShowingOverlay ? '-active' : ''
          }`}
          src={`/img/print-guide_${uchiwaSize}.png`}
        />
        {isWaitingSaveData ? null : (
          <img
            className="preview__side__images__entity"
            src={`/desigon/rgb/${tokenizedFileName}.png?h=${historyId}`}
            onLoad={() => setShowingOverlay(false)}
          />
        )}
      </div>
      <PreviewSideLoading isShowingOverlay={isShowingOverlay} />
      <p className={`preview__sideName`}>{sideName}</p>
    </div>
  )
}

export default PreviewSide
