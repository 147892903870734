import * as React from 'react'
import { dataset } from '~/utils/ponyfill'

type Props = {
  text: string
  onClick: (event: any) => void
  type?: string
  size?: string
  full?: boolean
  icon?: string
}
const Button: React.FC<Props> = ({ text, onClick, type, size, full, icon }) => {
  return (
    <div
      onClick={event => onClick(event)}
      className={`btn ${type ? '-' + type : ''} ${size ? '-' + size : ''} ${
        full ? '-full' : ''
      }`}
    >
      {text}
    </div>
  )
}

export default Button
