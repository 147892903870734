import * as React from 'react'
import { ModalAddDrawingCore } from '~/components/molecules'
import { ShapeTypes } from '@naire-seisakusho/react-almagest'
import type { OnPaletteDefines } from '~/hooks/onPaletteSourceDrawing'

type Props = {
  isActive: boolean
}

const droppedInitSizes = {
  width: 250,
  height: 0,
}

const colors = {
  basis: '#777',
}

const drawedAttributes = {
  solid: {
    stroke: colors.basis,
  },
  dotted: {
    stroke: colors.basis,
    strokeDasharray: '4 4', // ケバブはキャメルに
  },
}

const onPaletteDefines: OnPaletteDefines = {
  lineSolid: {
    shapeType: ShapeTypes.line,
    title: '直線',
    droppedInitSizes,
    drawedAttributes: drawedAttributes.solid,
  },
  lineDotted: {
    shapeType: ShapeTypes.line,
    title: '破線',
    droppedInitSizes,
    drawedAttributes: drawedAttributes.dotted,
  },
}

const sizeOnPaletteShorterSide = 50

// 僕が思ってるHOCと違う感じになった・・・
const ModalAddDrawingLine: React.FC<Props> = ({ isActive }) =>
  ModalAddDrawingCore({
    title: '線を追加',
    mainClassName: 'modalAddDrawingLine',
    onPaletteDefines, // この中で定義されてるのはドロップ後の縦横
    sizeOnPaletteShorterSide, // この数値はパレットでの短辺の長さ
    isActive,
  })

export default ModalAddDrawingLine
