import React from 'react'
type Props = {
  icon: string
  onClick?: (event: any) => void
  isDisabled?: boolean
}
const EditorElementsNavBtn: React.FC<Props> = ({
  icon,
  onClick,
  isDisabled,
}) => {
  const className = `editorElementNavBtn icon-${icon}`

  return (
    <div
      className={`${className} ${isDisabled ? '-disabled' : ''}`}
      onClick={event => onClick && onClick(event)}
    ></div>
  )
}

export default EditorElementsNavBtn
