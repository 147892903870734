import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { cloneDeep } from 'lodash-es'

import { useAlmagest, historiesModule } from '@naire-seisakusho/react-almagest'
import astralUi from '@naire-seisakusho/astral-ui'

import { useFetchDataLazyQuery } from '~/hooks/apolloAction'
import { useSocket } from '~/hooks/socket'

import { useSpica } from '~/context'

import { HomeTemplate } from '~/components/templates'

const { init, add, faceChange, selectHistoryIndex } = historiesModule

import { byenv } from '~/byenv'
const {
  hooks: { useDevHash, useScrollKeeper },
} = byenv

const Home: React.FC<{ face: 'front' | 'back' }> = ({ face }) => {
  useDevHash()
  const dispatch = useDispatch()
  const historyIndex = useSelector(selectHistoryIndex)
  const { svgJson, afterCommit, setAfterCommit, setAttachment } = useAlmagest()
  const {
    frontThumbnailState,
    backThumbnailState,
    uchiwaSizeState,
  } = useSpica()

  const [, setFrontThumbnail] = frontThumbnailState
  const [, setBackThumbnail] = backThumbnailState
  const [, setUchiwaSizeState] = uchiwaSizeState

  useScrollKeeper()
  // console.log(useLocation())

  type Params = {
    frontToken: string
    backToken: string
    face: string
  }
  const urlParams = useParams<Params>()
  const tokens = React.useMemo(
    () => ({
      front: urlParams.frontToken,
      back: urlParams.backToken,
    }),
    [urlParams]
  )

  React.useEffect(() => {
    if (face !== urlParams.face) return
    dispatch(faceChange(urlParams.face))
  }, [urlParams.face])

  const [fetchSvgData, svgData] = useFetchDataLazyQuery({
    variables: { token: tokens[face] },
  })

  const setThumbnail = (svgJson, guideSvg) => {
    const svg = cloneDeep(svgJson)
    if (guideSvg) {
      svg.children.push(guideSvg)
    }
    // guideSvg && svg.appendChild(guideSvg.cloneNode(true))
    face === 'front' ? setFrontThumbnail(svg) : setBackThumbnail(svg)
  }

  React.useEffect(() => {
    if (!tokens[face]) return

    fetchSvgData({ variables: { token: tokens[face] } })
  }, [tokens])

  const { commit } = useSocket()

  React.useEffect(() => {
    if (!svgData.data) return

    setUchiwaSizeState(svgData.data?.fetchData.typeId)
    const svgJson = JSON.parse(svgData.data?.fetchData.document)
    const guideIndex = svgJson.children.findIndex(
      (child) => child.attributes.id === 'guide'
    )
    const guideSvg = cloneDeep(svgJson.children[guideIndex])
    guideIndex !== -1 && svgJson.children.splice(guideIndex, 1)

    const setSvgJson = historyIndex === 0 ? init : add
    dispatch(setSvgJson({ document: svgJson, face, callback: afterCommit }))

    setThumbnail(svgJson, guideSvg)

    setAfterCommit((svgJson: Document) => {
      setThumbnail(svgJson, guideSvg)
      commit({
        token: tokens[face],
        svgJson,
        guideSvg: guideSvg || null,
      })
    })

    const animateSpawn = astralUi.for.spica.AnimateSpawn()
    setAttachment({ animateSpawn, guideSvg })
  }, [svgData.data])

  // useEventListener('load', async () => isLoadingState[1](false), window)

  if (face !== urlParams?.face) return null

  if (!svgJson) return null

  return <HomeTemplate />
}

export default Home
