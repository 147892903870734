// clientパッケージにもserverパッケージにもコピペ展開
export const createSvgson: any = {
  tag: (tagName: string): any => ({
    props: (propsJson: any): any => {
      // 一応、ディープコピーなしで副作用ありにしとく
      propsJson.name = tagName
      propsJson.type = 'element'
      propsJson.value = ''
      return propsJson
    },
  }),
}
