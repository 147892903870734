import type { Material, MaterialList } from '~/dedicated/materialList'
import { baseUrl } from '~/utils/baseUrl'
import { existsImageUrl } from '~/byenv/utils/existsImageUrl'

export const alertWhenHasUrlNotFoundOnIntra = async (
  materialList: MaterialList
) => {
  const Keys = {
    thumbnail: 'thumbnail',
    image: 'image',
  } as const
  type Key = keyof typeof Keys

  // ここは構造依存なのでkey文字列で取得とかはやらない
  const toUrl = (callback: (element: Material) => string) =>
    materialList.map(callback)
  const urls = {
    [Keys.thumbnail]: toUrl((material: Material) => material.url.thumbnail),
    [Keys.image]: toUrl((material: Material) => material.url.image),
  }

  const promiseAll = (key: Key) =>
    Promise.all(urls[key].map((url) => existsImageUrl(baseUrl + url)))

  const existsImageUrls = {
    [Keys.thumbnail]: await promiseAll(Keys.thumbnail),
    [Keys.image]: await promiseAll(Keys.image),
  }

  const toNotFoundUrls = (key: Key) =>
    urls[key].filter((_, index) => !existsImageUrls[key][index])

  const notFoundUrls = {
    thumbnail: toNotFoundUrls(Keys.thumbnail),
    image: toNotFoundUrls(Keys.image),
  }

  const toMessages = (key: string) =>
    notFoundUrls[key].length === 0
      ? []
      : [`${key}: `, ...notFoundUrls[key].map((url: string) => `- ${url}`)]

  const messages = [...toMessages(Keys.thumbnail), ...toMessages(Keys.image)]

  if (messages.length === 0) return
  messages.unshift('以下の画像が存在しないです。productionも要確認')
  alert(messages.join('\n'))
}
