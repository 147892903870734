import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'editTextElement',
  initialState: {
    align: '',
    body: '',
    fontFamily: '',
    fontSize: '',
  },
  reducers: {
    setEditTextElement: (state, action) => {
      const { align, body, fontFamily, fontSize } = action.payload

      state.align = align
      state.body = body
      state.fontFamily = fontFamily
      state.fontSize = fontSize
    },
  },
})

export const { setEditTextElement } = slice.actions

export const selectEditTextElement = state => state.editTextElement

export default slice.reducer
