import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { historiesModule } from '@naire-seisakusho/react-almagest'

import { useSpica } from '~/context'
import { SvgThumbnail } from '~/components/atoms'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const { faceChange } = historiesModule

const FaceThumbnailList: React.FC = () => {
  const dispatch = useDispatch()
  const { face, frontToken, backToken } = useParams() as {
    face: 'front' | 'back'
    frontToken: string
    backToken: string
  }

  const { frontThumbnailState, backThumbnailState } = useSpica()
  const [frontThumbnail] = frontThumbnailState
  const [backThumbnail] = backThumbnailState

  if (!frontThumbnail || !backThumbnail) return null

  const borderClass = (type: 'front' | 'back') => face === type && '-active'

  // とりあえず開発用
  const surfaceOrReverse = {
    front: {
      text: 'おもて',
      className: 'surface',
    },
    back: {
      text: 'うら',
      className: 'reverse',
    },
  }[face || 'front']

  return (
    <div
      className={switcss({
        designed: 'faceThumbnailList',
        framework: 'm-4',
        useFramework: false,
      })}
    >
      <Link
        to={{
          pathname: `/${frontToken}/${backToken}/front`,
          search: location.search,
        }}
      >
        <div
          className={switcss({
            designed: `faceThumbnailList__item ${borderClass('front')}`,
            framework: `bg-white text-center mb-4 ${borderClass('front')}`,
            useFramework: false,
          })}
          onClick={() => dispatch(faceChange('front'))}
        >
          <SvgThumbnail svg={frontThumbnail as Element} />
        </div>
      </Link>
      <p className="faceThumbnailList__name">おもて</p>

      <Link
        to={{
          pathname: `/${frontToken}/${backToken}/back`,
          search: location.search,
        }}
      >
        <div
          className={switcss({
            designed: `faceThumbnailList__item ${borderClass('back')}`,
            framework: `bg-white text-center ${borderClass('back')}`,
            useFramework: false,
          })}
          onClick={() => dispatch(faceChange('back'))}
        >
          <SvgThumbnail svg={backThumbnail as Element} />
        </div>
      </Link>
      <p className="faceThumbnailList__name">うら</p>
    </div>
  )
}

export default FaceThumbnailList
