export const switcss = ({
  designed = '',
  framework = '',
  useFramework = false,
}: {
  designed: string
  framework: string
  useFramework: boolean
}) => designed.trim() + (useFramework ? ` ${framework.trim()}` : '')

export default switcss
