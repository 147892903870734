import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { showModalChangeText } from '~/modules/modals'
import { setEditTextElement } from '~/modules/editTextElement'

import { Align } from '~/hooks/apolloAction'

import { ColorPicker } from '~/components/molecules'

import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

type Props = {
  element: any
  effectAttributes: (attributes: Attributes) => void

  storeAttributes: (attributes: Attributes) => void
  storeElement: (element: any) => void
}
const PropertiesText: React.FC<Props> = ({
  element,
  effectAttributes,
  storeAttributes,
  storeElement,
}) => {
  const dispatch = useDispatch()

  const { fill } = element.attributes
  const { align, body, fontFamily, fontSize } = element.almagest.fontOption

  const canLayoutChange = element.children[0].children[0].children.length > 1

  const handleUpdateText = () => {
    setEditTextElement({ align, body, fontFamily, fontSize })
    dispatch(showModalChangeText())
  }

  const setAlign = align => {
    const maxWidth =
      element.almagest.positionsInit.dstX - element.almagest.positionsInit.srcX

    const cloneElement = JSON.parse(JSON.stringify(element))

    cloneElement.children[0].children[0].children = cloneElement.children[0].children[0].children.map(
      (item, i) => {
        const translateLeft = (() => {
          const widthDiff = maxWidth - item.almagest.width
          if (align === Align.Right) return widthDiff
          if (align === Align.Center) return widthDiff / 2
          return 0
        })()

        const translateTop = item.almagest.height * i

        item.attributes = {
          ...item.attributes,
          transform: `translate(${translateLeft}, ${translateTop})`,
        }

        return item
      }
    )
    cloneElement.almagest.fontOption.align = align

    storeElement(cloneElement)
  }

  const setFill = hex => {
    const cloneElement = JSON.parse(JSON.stringify(element))

    cloneElement.children[0].children[0].children = cloneElement.children[0].children[0].children.map(
      item => {
        item.attributes.fill = hex

        return item
      }
    )
    cloneElement.attributes.fill = hex

    storeElement(cloneElement)
  }

  return (
    <>
      <div className="propertyEdit__group">
        <button className="btn -primary -lg -full" onClick={handleUpdateText}>
          文字の内容や設定を編集する
        </button>
      </div>
      <div className="propertyEdit__group">
        <h3 className="propertyEdit__title">塗り</h3>
        <div className="propertyEdit__content">
          <div className="propertyList">
            <div className="propertyItem">
              <p className="propertyItem__name">
                <span className="inputLabel">色</span>
              </p>
              <div className="propertyItem__main">
                <ColorPicker
                  color={fill}
                  onChange={hex => effectAttributes({ fill: hex })}
                  onChangeComplete={hex => setFill(hex)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {canLayoutChange && (
        <div className="propertyEdit__group">
          <h3 className="propertyEdit__title">レイアウト</h3>
          <div className="propertyEdit__content">
            <div className="propertyList">
              <div className="propertyItem">
                <p className="propertyItem__name">
                  <span className="inputLabel">横揃え</span>
                </p>
                <div className="propertyItem__main">
                  <button
                    className={`iconCheckBtn -align-left ${
                      align == 'LEFT' ? '-active' : ''
                    }`}
                    onClick={() => setAlign('LEFT')}
                  ></button>
                  <button
                    className={`iconCheckBtn -align-center ${
                      align == 'CENTER' ? '-active' : ''
                    }`}
                    onClick={() => setAlign('CENTER')}
                  ></button>
                  <button
                    className={`iconCheckBtn -align-right ${
                      align == 'RIGHT' ? '-active' : ''
                    }`}
                    onClick={() => setAlign('RIGHT')}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PropertiesText
