import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'
import urlParser from 'url-parse'

import { useAlmagest, historiesModule } from '@naire-seisakusho/react-almagest'

import {
  showModalAddText,
  showModalAddDrawingShape,
  showModalAddDrawingLine,
  showModalAddMaterial,
  selectModalState,
} from '~/modules/modals'

import { useUploadImage } from '~/hooks/uploadImage'

import { EditorMenuBtn } from '~/components/atoms'

const { undo, redo, selectHasUndo, selectHasRedo } = historiesModule

type Props = {}
const EditorMenu: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const modalState = useSelector(selectModalState)
  const { afterCommit } = useAlmagest()
  const { uploadFileRef, handleFileSelect } = useUploadImage()

  const { rt } = queryString.parse(location.search) as { rt: string }
  const parceUrl = urlParser(rt)
  const { product_id: productId } = queryString.parse(parceUrl.query as any)
  const prevUrl = `${parceUrl.origin}/products/${productId}`

  const hasUndo = useSelector(selectHasUndo)
  const hasRedo = useSelector(selectHasRedo)

  const [isShowingUploadDialog, setShowingUploadDialog] = React.useState(false)

  const closeUploadDialogListener = () => {
    setShowingUploadDialog(false)
    window.removeEventListener('focus', closeUploadDialogListener)
  }

  const addCloseUploadDialogListener = () => {
    setShowingUploadDialog(true)
    window.addEventListener('focus', closeUploadDialogListener)
  }

  return (
    <div className="editorMenu">
      <div className="col">
        <EditorMenuBtn
          text="前の画面"
          icon="back"
          onClick={() => (location.href = prevUrl)}
        />
      </div>

      <div className="col">
        <EditorMenuBtn
          text="ひとつ戻る"
          icon="undo"
          isDisabled={!hasUndo}
          onClick={() => dispatch(undo({ callback: afterCommit }))}
        />
        <EditorMenuBtn
          text="ひとつ進む"
          icon="redo"
          isDisabled={!hasRedo}
          onClick={() => dispatch(redo({ callback: afterCommit }))}
        />
      </div>

      <div className="col">
        <EditorMenuBtn
          text="文字を追加"
          icon="text"
          isActive={modalState.modalEditText}
          onClick={() => dispatch(showModalAddText())}
        />

        <EditorMenuBtn
          text="図形を追加"
          icon="shape"
          isActive={modalState.modalAddDrawingShape}
          onClick={() => dispatch(showModalAddDrawingShape())}
        />

        <EditorMenuBtn
          text="線を追加"
          icon="path"
          isActive={modalState.modalAddDrawingLine}
          onClick={() => dispatch(showModalAddDrawingLine())}
        />

        <label htmlFor="editorMenuUploadImage">
          <EditorMenuBtn
            isActive={isShowingUploadDialog}
            onClick={addCloseUploadDialogListener}
            text="画像を追加"
            icon="image"
          >
            <input
              id="editorMenuUploadImage"
              className="hidden"
              type="file"
              ref={uploadFileRef}
              onChange={handleFileSelect}
            />
          </EditorMenuBtn>
        </label>

        <EditorMenuBtn
          text="素材を追加"
          icon="material"
          isActive={modalState.modalAddMaterial}
          onClick={() => dispatch(showModalAddMaterial())}
        />
      </div>

      <div className="col">
        <EditorMenuBtn text="ヘルプ" icon="help" isDisabled={true} />
      </div>
    </div>
  )
}
export default EditorMenu
