import * as React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const ConfirmFooter: React.FC = () => {
  const { frontToken, backToken } = useParams()

  return (
    <nav
      className={switcss({
        designed: '',
        framework:
          'flex items-center justify-between flex-wrap bg-gray-700 p-6',
        useFramework: true,
      })}
    >
      <div
        className={switcss({
          designed: '',
          framework: 'flex items-center flex-shrink-0 text-white mr-6',
          useFramework: true,
        })}
      >
        <Link
          to={`/${frontToken}/${backToken}/front`}
          className={switcss({
            designed: '',
            framework:
              'bg-gray-500 text-white font-bold py-2 px-4 rounded outline-none',
            useFramework: true,
          })}
        >
          戻る
        </Link>
      </div>
      <div
        className={switcss({
          designed: '',
          framework: 'flex items-center flex-shrink-0 text-white mr-6',
          useFramework: true,
        })}
      >
        <a
          href="#"
          className={switcss({
            designed: '',
            framework:
              'bg-green-500 text-white font-bold py-2 px-4 rounded outline-none',
            useFramework: true,
          })}
        >
          確定
        </a>
      </div>
    </nav>
  )
}

export default ConfirmFooter
