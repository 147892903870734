import { useDispatch } from 'react-redux'
import {
  hideOverlay,
  startWaitingResponse,
  endWaitingResponse,
} from '~/modules/modals'

export const useWaitingResponse = () => {
  const dispatch = useDispatch()
  return {
    start: startReducer => {
      dispatch(startReducer())
      dispatch(startWaitingResponse())
    },
    end: () => {
      dispatch(hideOverlay())
      dispatch(endWaitingResponse())
    },
  }
}
