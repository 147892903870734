import { arrayToJson } from './arrayToJson'
const userAgentText = window.navigator.userAgent.toLowerCase()
const unknownKey = 'unknown'

// 判定の順番に意味があるので注意
const browserByUserAgentIncludes = {
  ieOld: 'msie',
  ie11: 'trident',
  edge: 'edge',
  chrome: 'chrome',
  safari: 'safari',
  firefox: 'firefox',
  opera: 'opera',
  [unknownKey]: null,
}

const includes = (partialName) => userAgentText.indexOf(partialName) !== -1
const matchedKey =
  Object.keys(browserByUserAgentIncludes).find((key) =>
    includes(browserByUserAgentIncludes[key])
  ) || unknownKey

export const userAgent = {
  browser: arrayToJson(Object.keys(browserByUserAgentIncludes), (json, key) => {
    json[key] = key === matchedKey
  }),
}

export default userAgent
