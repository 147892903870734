import * as React from 'react'
import {
  PaletteDrawings,
  PalettePresets,
  PaletteTextInput,
  PaletteUploadedImages,
} from '~/byenv/components/sub'
const SideTabPanelPaletteContent: React.FC = () => {
  return (
    <>
      <div className="hr-text">画像</div>
      <PaletteUploadedImages />
      <div className="hr-text">描画図形</div>
      <PaletteDrawings />
      <div className="hr-text">プリセット図形</div>
      <PalettePresets />
      <div className="hr-text">テキスト</div>
      <PaletteTextInput />
    </>
  )
}
export default SideTabPanelPaletteContent
