import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export enum Align {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT',
}

export interface CreateToken {
  __typename?: 'CreateToken'
  frontToken: Scalars['String']
  backToken: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
}

export interface FetchData {
  __typename?: 'FetchData'
  typeId: Scalars['String']
  document: Scalars['String']
  token: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
}

export interface Mutation {
  __typename?: 'Mutation'
  pathfy: Pathfy
  uploadPhoto?: Maybe<Array<Photo>>
  removePhoto?: Maybe<Array<Photo>>
  createToken: CreateToken
  preview: Preview
  saveData: SaveData
}

export interface MutationPathfyArgs {
  text: Scalars['String']
  align?: Maybe<Align>
  font?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
}

export interface MutationUploadPhotoArgs {
  token: Scalars['String']
  file: Scalars['String']
}

export interface MutationRemovePhotoArgs {
  token: Scalars['String']
  filename: Scalars['String']
}

export interface MutationCreateTokenArgs {
  typeId: Scalars['String']
}

export interface MutationPreviewArgs {
  token: Scalars['String']
}

export interface MutationSaveDataArgs {
  token: Scalars['String']
}

export interface Pathfy {
  __typename?: 'Pathfy'
  path: Scalars['String']
}

export interface Photo {
  __typename?: 'Photo'
  url: Scalars['String']
}

export interface Preview {
  __typename?: 'Preview'
  url?: Maybe<Scalars['String']>
}

export interface Query {
  __typename?: 'Query'
  fetchPhotos?: Maybe<Array<Photo>>
  fetchData: FetchData
}

export interface QueryFetchPhotosArgs {
  token: Scalars['String']
}

export interface QueryFetchDataArgs {
  token: Scalars['String']
  store?: Maybe<Store>
}

export interface SaveData {
  __typename?: 'SaveData'
  token: Scalars['String']
}

export enum Store {
  Editing = 'EDITING',
  Saved = 'SAVED',
}

export type PathfyMutationVariables = {
  text: Scalars['String']
  align?: Maybe<Align>
  font?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
}

export type PathfyMutation = { __typename?: 'Mutation' } & {
  pathfy: { __typename?: 'Pathfy' } & Pick<Pathfy, 'path'>
}

export type UploadPhotoMutationVariables = {
  token: Scalars['String']
  file: Scalars['String']
}

export type UploadPhotoMutation = { __typename?: 'Mutation' } & {
  uploadPhoto: Maybe<Array<{ __typename?: 'Photo' } & Pick<Photo, 'url'>>>
}

export type RemovePhotoMutationVariables = {
  token: Scalars['String']
  filename: Scalars['String']
}

export type RemovePhotoMutation = { __typename?: 'Mutation' } & {
  removePhoto: Maybe<Array<{ __typename?: 'Photo' } & Pick<Photo, 'url'>>>
}

export type FetchPhotosQueryVariables = {
  token: Scalars['String']
}

export type FetchPhotosQuery = { __typename?: 'Query' } & {
  fetchPhotos: Maybe<Array<{ __typename?: 'Photo' } & Pick<Photo, 'url'>>>
}

export type CreateTokenMutationVariables = {
  typeId: Scalars['String']
}

export type CreateTokenMutation = { __typename?: 'Mutation' } & {
  createToken: { __typename?: 'CreateToken' } & Pick<
    CreateToken,
    'frontToken' | 'backToken'
  >
}

export type PreviewMutationVariables = {
  token: Scalars['String']
}

export type PreviewMutation = { __typename?: 'Mutation' } & {
  preview: { __typename?: 'Preview' } & Pick<Preview, 'url'>
}

export type SaveDataMutationVariables = {
  token: Scalars['String']
}

export type SaveDataMutation = { __typename?: 'Mutation' } & {
  saveData: { __typename?: 'SaveData' } & Pick<SaveData, 'token'>
}

export type FetchDataQueryVariables = {
  token: Scalars['String']
  store?: Maybe<Store>
}

export type FetchDataQuery = { __typename?: 'Query' } & {
  fetchData: { __typename?: 'FetchData' } & Pick<
    FetchData,
    'typeId' | 'document' | 'token' | 'createdAt'
  >
}

export const PathfyDocument = gql`
  mutation pathfy($text: String!, $align: Align, $font: String, $size: Int) {
    pathfy(text: $text, align: $align, font: $font, size: $size) {
      path
    }
  }
`
export type PathfyMutationFn = ApolloReactCommon.MutationFunction<
  PathfyMutation,
  PathfyMutationVariables
>

/**
 * __usePathfyMutation__
 *
 * To run a mutation, you first call `usePathfyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePathfyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pathfyMutation, { data, loading, error }] = usePathfyMutation({
 *   variables: {
 *      text: // value for 'text'
 *      align: // value for 'align'
 *      font: // value for 'font'
 *      size: // value for 'size'
 *   },
 * });
 */
export function usePathfyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PathfyMutation,
    PathfyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<PathfyMutation, PathfyMutationVariables>(
    PathfyDocument,
    baseOptions
  )
}
export type PathfyMutationHookResult = ReturnType<typeof usePathfyMutation>
export type PathfyMutationResult = ApolloReactCommon.MutationResult<
  PathfyMutation
>
export type PathfyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PathfyMutation,
  PathfyMutationVariables
>
export const UploadPhotoDocument = gql`
  mutation uploadPhoto($token: String!, $file: String!) {
    uploadPhoto(token: $token, file: $file) {
      url
    }
  }
`
export type UploadPhotoMutationFn = ApolloReactCommon.MutationFunction<
  UploadPhotoMutation,
  UploadPhotoMutationVariables
>

/**
 * __useUploadPhotoMutation__
 *
 * To run a mutation, you first call `useUploadPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPhotoMutation, { data, loading, error }] = useUploadPhotoMutation({
 *   variables: {
 *      token: // value for 'token'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadPhotoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadPhotoMutation,
    UploadPhotoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadPhotoMutation,
    UploadPhotoMutationVariables
  >(UploadPhotoDocument, baseOptions)
}
export type UploadPhotoMutationHookResult = ReturnType<
  typeof useUploadPhotoMutation
>
export type UploadPhotoMutationResult = ApolloReactCommon.MutationResult<
  UploadPhotoMutation
>
export type UploadPhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadPhotoMutation,
  UploadPhotoMutationVariables
>
export const RemovePhotoDocument = gql`
  mutation removePhoto($token: String!, $filename: String!) {
    removePhoto(token: $token, filename: $filename) {
      url
    }
  }
`
export type RemovePhotoMutationFn = ApolloReactCommon.MutationFunction<
  RemovePhotoMutation,
  RemovePhotoMutationVariables
>

/**
 * __useRemovePhotoMutation__
 *
 * To run a mutation, you first call `useRemovePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePhotoMutation, { data, loading, error }] = useRemovePhotoMutation({
 *   variables: {
 *      token: // value for 'token'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useRemovePhotoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemovePhotoMutation,
    RemovePhotoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemovePhotoMutation,
    RemovePhotoMutationVariables
  >(RemovePhotoDocument, baseOptions)
}
export type RemovePhotoMutationHookResult = ReturnType<
  typeof useRemovePhotoMutation
>
export type RemovePhotoMutationResult = ApolloReactCommon.MutationResult<
  RemovePhotoMutation
>
export type RemovePhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemovePhotoMutation,
  RemovePhotoMutationVariables
>
export const FetchPhotosDocument = gql`
  query fetchPhotos($token: String!) {
    fetchPhotos(token: $token) {
      url
    }
  }
`

/**
 * __useFetchPhotosQuery__
 *
 * To run a query within a React component, call `useFetchPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPhotosQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchPhotosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FetchPhotosQuery,
    FetchPhotosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FetchPhotosQuery, FetchPhotosQueryVariables>(
    FetchPhotosDocument,
    baseOptions
  )
}
export function useFetchPhotosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FetchPhotosQuery,
    FetchPhotosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FetchPhotosQuery,
    FetchPhotosQueryVariables
  >(FetchPhotosDocument, baseOptions)
}
export type FetchPhotosQueryHookResult = ReturnType<typeof useFetchPhotosQuery>
export type FetchPhotosLazyQueryHookResult = ReturnType<
  typeof useFetchPhotosLazyQuery
>
export type FetchPhotosQueryResult = ApolloReactCommon.QueryResult<
  FetchPhotosQuery,
  FetchPhotosQueryVariables
>
export const CreateTokenDocument = gql`
  mutation createToken($typeId: String!) {
    createToken(typeId: $typeId) {
      frontToken
      backToken
    }
  }
`
export type CreateTokenMutationFn = ApolloReactCommon.MutationFunction<
  CreateTokenMutation,
  CreateTokenMutationVariables
>

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useCreateTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTokenMutation,
    CreateTokenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTokenMutation,
    CreateTokenMutationVariables
  >(CreateTokenDocument, baseOptions)
}
export type CreateTokenMutationHookResult = ReturnType<
  typeof useCreateTokenMutation
>
export type CreateTokenMutationResult = ApolloReactCommon.MutationResult<
  CreateTokenMutation
>
export type CreateTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTokenMutation,
  CreateTokenMutationVariables
>
export const PreviewDocument = gql`
  mutation preview($token: String!) {
    preview(token: $token) {
      url
    }
  }
`
export type PreviewMutationFn = ApolloReactCommon.MutationFunction<
  PreviewMutation,
  PreviewMutationVariables
>

/**
 * __usePreviewMutation__
 *
 * To run a mutation, you first call `usePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewMutation, { data, loading, error }] = usePreviewMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePreviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PreviewMutation,
    PreviewMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PreviewMutation,
    PreviewMutationVariables
  >(PreviewDocument, baseOptions)
}
export type PreviewMutationHookResult = ReturnType<typeof usePreviewMutation>
export type PreviewMutationResult = ApolloReactCommon.MutationResult<
  PreviewMutation
>
export type PreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PreviewMutation,
  PreviewMutationVariables
>
export const SaveDataDocument = gql`
  mutation saveData($token: String!) {
    saveData(token: $token) {
      token
    }
  }
`
export type SaveDataMutationFn = ApolloReactCommon.MutationFunction<
  SaveDataMutation,
  SaveDataMutationVariables
>

/**
 * __useSaveDataMutation__
 *
 * To run a mutation, you first call `useSaveDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDataMutation, { data, loading, error }] = useSaveDataMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSaveDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveDataMutation,
    SaveDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SaveDataMutation,
    SaveDataMutationVariables
  >(SaveDataDocument, baseOptions)
}
export type SaveDataMutationHookResult = ReturnType<typeof useSaveDataMutation>
export type SaveDataMutationResult = ApolloReactCommon.MutationResult<
  SaveDataMutation
>
export type SaveDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveDataMutation,
  SaveDataMutationVariables
>
export const FetchDataDocument = gql`
  query fetchData($token: String!, $store: Store = EDITING) {
    fetchData(token: $token, store: $store) {
      typeId
      document
      token
      createdAt
    }
  }
`

/**
 * __useFetchDataQuery__
 *
 * To run a query within a React component, call `useFetchDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDataQuery({
 *   variables: {
 *      token: // value for 'token'
 *      store: // value for 'store'
 *   },
 * });
 */
export function useFetchDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FetchDataQuery,
    FetchDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FetchDataQuery, FetchDataQueryVariables>(
    FetchDataDocument,
    baseOptions
  )
}
export function useFetchDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FetchDataQuery,
    FetchDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<FetchDataQuery, FetchDataQueryVariables>(
    FetchDataDocument,
    baseOptions
  )
}
export type FetchDataQueryHookResult = ReturnType<typeof useFetchDataQuery>
export type FetchDataLazyQueryHookResult = ReturnType<
  typeof useFetchDataLazyQuery
>
export type FetchDataQueryResult = ApolloReactCommon.QueryResult<
  FetchDataQuery,
  FetchDataQueryVariables
>
