import * as React from 'react'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

import { ColorPicker } from '~/components/molecules'

type Props = {
  attributes: { color: string }
  effectAttributes: (attributes: Attributes) => void
  storeAttributes: (attributes: Attributes) => void
}
const PropertiesFill: React.FC<Props> = ({
  attributes,
  effectAttributes,
  storeAttributes,
}) => {
  const { color } = attributes
  return (
    <div className='propertyList'>
      <div className='propertyItem'>
        <p className='propertyItem__name'>
          <span className="inputLabel">色</span>
        </p>
        <div className="propertyItem__main">
          <ColorPicker
            color={color}
            onChange={hex => effectAttributes({ fill: hex })}
            onChangeComplete={hex => storeAttributes({ fill: hex })}
          />
        </div>
      </div>
    </div>
  )
}

export default PropertiesFill
