// byenvに移すべきサブコンポーネント有無を要確認
import * as React from 'react'
import { useOnDropSourceToEditorCreator } from '~/hooks/onDropSourceToEditorCreator'
import DragSourceImg from '~/byenv/components/sub/DragSourceImg'

import { onPaletteSizes } from '~/hooks/toDroppedPositionSizes'

const PaletteDrawings: React.FC = () => {
  const onDropSourceToEditorCreator = useOnDropSourceToEditorCreator()

  const shapes = ['/svg/nestjs-seeklogo.com.svg']

  return (
    <div className="palette">
      {shapes.map((src, i) => (
        <DragSourceImg
          src={src}
          onDrop={onDropSourceToEditorCreator.preset(src)}
          sizes={onPaletteSizes}
          isDropped={false}
          key={i}
        />
      ))}
    </div>
  )
}

export default PaletteDrawings
