import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  historiesModule,
  inputDeviceModule,
} from '@naire-seisakusho/react-almagest'

import { switcss } from '~/utils/switcss'

/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const { selectHasUndo, selectHasRedo } = historiesModule
const { selectMouse, selectKey } = inputDeviceModule

const HotKeyGuide: React.FC = () => {
  const hasUndo = useSelector(selectHasUndo)
  const hasRedo = useSelector(selectHasRedo)
  const mouseStatus = useSelector(selectMouse)
  const keyStatus = useSelector(selectKey)

  // return (
  //   <>
  //     <div>{JSON.stringify(mouseStatus)}</div>
  //     <div>{JSON.stringify(keyStatus)}</div>
  //   </>
  // )

  const show: string[] = []
  hasUndo && show.push('⌘zもとに戻す')
  hasRedo && show.push('⌘yやり直す')

  if (show.length === 0) return null

  return (
    <div
      className={switcss({
        designed: '',
        framework: 'absolute bottom-0 left-0 m-4',
        useFramework: true,
      })}
    >
      <div
        className={switcss({
          designed: '',
          framework: 'bg-gray-500 text-white p-2 rounded-full',
          useFramework: true,
        })}
      >
        {show.map((text, i) => (
          <span
            className={switcss({
              designed: '',
              framework: 'pr-2',
              useFramework: true,
            })}
            key={i}
          >
            {text}
          </span>
        ))}
      </div>
    </div>
  )
}

export default HotKeyGuide
