import * as React from 'react'

type Props = {
  value: number
  minimum: number
  maximum: number
  onChange: (value: number) => void
}
const NumericInput: React.FC<Props> = ({
  value,
  minimum,
  maximum,
  onChange,
}) => {
  const increment = () => {
    if (value >= maximum) return

    const changed = value + 1
    onChange(changed)
  }
  const decrement = () => {
    if (value <= minimum) return

    const changed = value - 1
    onChange(changed)
  }

  return (
    <div className="numericInput">
      <button className="numericInput__decrement" onClick={decrement}>
        -
      </button>

      <input
        className="numericInput__input"
        type="number"
        name=""
        id=""
        value={value}
        min={minimum}
        max={maximum}
        onChange={() => onChange(value)}
      />

      <button className="numericInput__increment" onClick={increment}>
        +
      </button>
    </div>
  )
}

export default NumericInput
