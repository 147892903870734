export const uchiwaSizeStrangeDefault = 'reg'
export const uchiwaSizeQueryStringValueDefault = '1'
export const uchiwaSizeLogicalDefault = 'M'
// packages\client\src\routes\guard.tsxにあったこれを1～3じゃなくて
// 隔離層としてM/S/XSに再命名すればよかったのでは・・・
export const uchiwaSizeStrangeByQueryStringValues = {
  [uchiwaSizeQueryStringValueDefault]: uchiwaSizeStrangeDefault, // M
  '2': 'com', // S
  '3': 'mid', // XS
}

// リテラル1～3が他にどこで使われてるか見つけにくいのでリスク避けて論理化
export const uchiwaSizeQueryStringValueByLogicals = {
  [uchiwaSizeLogicalDefault]: uchiwaSizeQueryStringValueDefault,
  S: '2',
  XS: '3',
}
export const uchiwaSizeLogicalToStrange = (uchiwaSizeLogical: string) =>
  uchiwaSizeStrangeByQueryStringValues[
    uchiwaSizeQueryStringValueByLogicals[uchiwaSizeLogical]
  ]

const findKeyByValueMatch = (json, value) =>
  Object.keys(json).find(key => json[key] === value)
export const uchiwaSizeStrangeToQueryStringValue = uchiwaSizeStrange =>
  findKeyByValueMatch(uchiwaSizeStrangeByQueryStringValues, uchiwaSizeStrange)
export const uchiwaSizeStrangeToLogical = (uchiwaSizeStrange: string) =>
  findKeyByValueMatch(
    uchiwaSizeQueryStringValueByLogicals,
    uchiwaSizeStrangeToQueryStringValue(uchiwaSizeStrange)
  )

// // ゆにて
// console.log(['M', 'S', 'XS'].map(key => uchiwaSizeLogicalToStrange(key)))
// console.log(
//   ['reg', 'com', 'mid'].map(key => uchiwaSizeStrangeToQueryStringValue(key))
// )
// console.log(['reg', 'com', 'mid'].map(key => uchiwaSizeStrangeToLogical(key)))
