import { useAlmagest } from '@naire-seisakusho/react-almagest'

type Sizes = {
  width: number
  height: number
}

export const maxSizeOnDroppedGeneral = 300

export const onPaletteSizes = {
  width: 100,
  height: 100,
}

// コピペコード排除のために抽出したものの、実際はuseAlmagest().almagest.sight.origin
// と同値のはず
// ちょっと様子見のために残す
const getAlmagestSightOrigin = () => {
  const { left, top } = document
    ?.getElementById('paper')
    ?.getBoundingClientRect() || {
    top: 0,
    left: 0,
  }
  return { x: left, y: top }
}

const normalize = (sizes: Sizes, maxSize: number | Sizes): any => {
  const applyingScale = (() => {
    if (typeof maxSize === 'number') {
      if (maxSize === 0) return 1
      if (maxSize >= sizes.height && maxSize >= sizes.width) return 1
      return maxSize / (sizes.height > sizes.width ? sizes.height : sizes.width)
    }
    const maxSizes = maxSize
    const widthRatio = maxSizes.width / sizes.width
    const heightRatio = maxSizes.height / sizes.height
    return heightRatio > widthRatio ? widthRatio : heightRatio
  })()
  return {
    width: sizes.width * applyingScale,
    height: sizes.height * applyingScale,
  }
}

export const useToDroppedPositionSizes = () => {
  const almagest = useAlmagest()
  const toDroppedPositionSizes = (
    mouse: { x: number; y: number },
    droppedInitSizes: { width: number; height: number },
    maxSizeOnDropped: number | { width: number; height: number } = 0
  ): {
    droppedPositions: { x: number; y: number }
    droppedSizes: { width: number; height: number }
  } => {
    const paperScale = almagest.paperScale
    const origin = almagest.almagest.sight.origin

    const scrolled = {
      x: document.documentElement.scrollLeft,
      y: document.documentElement.scrollTop,
    }

    // originがscrollLeft/scrollTopを足して渡されてるので、
    // 改めて引かないといけないのか
    const mouseOnPaper = {
      x: mouse.x - origin.x - scrolled.x,
      y: mouse.y - origin.y - scrolled.y,
    }

    const normalizedDroppedInitSize = normalize(
      droppedInitSizes,
      maxSizeOnDropped
    )

    const adjustingSizes = {
      x: normalizedDroppedInitSize.width / 2,
      y: normalizedDroppedInitSize.height / 2,
    }

    const adjustedPositions = {
      x: mouseOnPaper.x - adjustingSizes.x,
      y: mouseOnPaper.y - adjustingSizes.y,
    }

    const paperScaledPositions = {
      x: adjustedPositions.x * paperScale,
      y: adjustedPositions.y * paperScale,
    }

    return {
      droppedPositions: paperScaledPositions,
      droppedSizes: normalizedDroppedInitSize,
    }
  }
  return {
    toDroppedPositionSizes,
  }
}
