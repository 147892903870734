import * as React from 'react'
import { ModalAddOutline, ClickSourceDrawing } from '~/components/molecules'
import { ModalCloseBtn } from '~/components/atoms'
import { ShapeTypeOriginSizesToAttrbutes } from '@naire-seisakusho/react-almagest'
import {
  useOnPaletteSourceDrawing,
  OnPaletteDefines,
} from '~/hooks/onPaletteSourceDrawing'

type Props = {
  title: string
  mainClassName: string
  isActive: boolean
  onPaletteDefines: OnPaletteDefines
  sizeOnPaletteShorterSide: number
}

// 僕が思ってるHOCと違う感じになった・・・
const ModalAddDrawingCore: React.FC<Props> = ({
  title,
  mainClassName,
  isActive,
  onPaletteDefines,
  sizeOnPaletteShorterSide,
}) => {
  const shapeOnPaletteSources = useOnPaletteSourceDrawing(
    onPaletteDefines, // この中で定義されてるのはドロップ後の縦横
    sizeOnPaletteShorterSide // この数値はパレットでの短辺の長さ
  )
  return (
    <ModalAddOutline
      title={title}
      mainClassName={mainClassName}
      isActive={isActive}
      content={({ contentClassName }) => (
        <div className="modalContent">
          <div className="modalContent__body">
            <div className="shapeList">
              {Object.keys(shapeOnPaletteSources).map((shapeTypeOnPallete) => {
                const {
                  shapeType,
                  svgson,
                  onPaletteSourceSizes,
                  onPaletteShapeOrigin,
                  onPaletteShapeSizes,
                  title,
                  onDropSourceToEditor,
                } = shapeOnPaletteSources[shapeTypeOnPallete]

                const ShapeOnPaletteTagName = svgson.name

                const drawedAttributes = svgson.attributes
                const positionAttributes = ShapeTypeOriginSizesToAttrbutes[
                  shapeType
                ]
                  .origin(onPaletteShapeOrigin)
                  .sizes(onPaletteShapeSizes)

                const ShapeOnPalette = () => (
                  <ShapeOnPaletteTagName
                    {...{
                      ...drawedAttributes,
                      ...positionAttributes,
                    }}
                  />
                )

                return (
                  <ClickSourceDrawing
                    title={title}
                    onDropSourceToEditor={onDropSourceToEditor}
                    key={onDropSourceToEditor.key}
                  >
                    <svg
                      width={onPaletteSourceSizes.width}
                      height={onPaletteSourceSizes.height}
                      viewBox={`0 0 ${onPaletteSourceSizes.width} ${onPaletteSourceSizes.height}`}
                    >
                      <ShapeOnPalette />
                    </svg>
                  </ClickSourceDrawing>
                )
              })}
            </div>
          </div>
          <div className="modalContent__foot">
            <div className="btnList">
              <ModalCloseBtn />
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default ModalAddDrawingCore
