import React from 'react'

import { useEventListener } from '~/hooks/eventListener'

// ここから先のanyの解決方法がわかめ
const EventListenable = <T>(ref: any) => {
  const returns = {
    addEventListener: (
      eventName: string,
      handler: (event: T) => void,
      useCapture = false
    ) => {
      useEventListener<T>(eventName, handler, ref?.current, useCapture)
      return returns
    },
  }
  return returns
}

const initDragProps = { opacity: 1, cursor: 'grab' }

const entries = {}

export const useDragOriginal = (onDrop: any): any => {
  const { opacity, cursor } = initDragProps
  const dragSourceRef = React.useRef<HTMLDivElement>(null)
  const dragSource = EventListenable<DragEvent>(dragSourceRef)

  // 保持意味ない気がする
  // const index = entries.length
  // entries[index] = createElement

  entries[onDrop.key] = onDrop.createElement

  dragSourceRef.current?.setAttribute('draggable', 'true')

  const eventNames = [
    'drag',
    'dragend',
    'dragenter',
    'dragexit',
    'dragleave',
    'dragover',
    'dragstart',
    'drop',
  ]

  const uniqueEvents = {
    dragstart: (event: DragEvent) => {
      event?.dataTransfer?.setData('text', onDrop.key)
    },
  }

  eventNames.forEach((eventName) => {
    dragSource.addEventListener(eventName, (event: DragEvent) => {
      const uniqueEvent = uniqueEvents[eventName]
      if (uniqueEvent) uniqueEvent(event)

      // console.log(eventName)
    })
  })

  return [{ opacity, cursor }, dragSourceRef]
}

export const useSetDropTargetRefForDragOriginal = (
  dropTargetRef: React.RefObject<HTMLElement>
) => {
  const dropTarget = EventListenable<DragEvent>(dropTargetRef)
  dropTarget.addEventListener('drop', (event) => {
    // 保持意味ない気がする
    const key = event.dataTransfer?.getData('text') as string
    if (entries[key])
      entries[key]({
        x: event.pageX,
        y: event.pageY,
      })
  })
}
