import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlmagest, targetModule } from '@naire-seisakusho/react-almagest'

import { useOnDropShapeLife } from '~/hooks/onDropShapeLife'
import { hideAllModal, selectTextAction } from '~/modules/modals'

import { useWaitingResponse } from '~/hooks/waitingResponse'
import { usePathfyMutation, Align } from '~/hooks/apolloAction'
import { useOnDropSourceToEditorCreator } from '~/hooks/onDropSourceToEditorCreator'
import { useUpdateDocument } from '~/hooks/updateDocument'

import { ModalAddOutline } from '~/components/molecules'
import { ModalCloseBtn } from '~/components/atoms'
import { classAccessor } from '~/utils/classAccessor'

type Props = {
  isActive: boolean
}

const fontFamilies = {
  'MPLUS1p-Regular': 'ゴシック',
  'LT-MatisseN-M': '明朝',
  'MPLUS1p-Bold': '太字ゴシック',
  'MPLUSRounded1c-Regular': '丸字ゴシック',
  'LT-PopHappiness-EB': 'ポップ',
  'LT-Klee-M': 'ペン字',
}

const aligns = {
  left: 'LEFT',
  center: 'CENTER',
  right: 'RIGHT',
}

const { selectTargetId } = targetModule

// 僕が思ってるHOCと違う感じになった・・・
const ModalAddDrawingText: React.FC<Props> = ({ isActive }) => {
  const dispatch = useDispatch()
  const { startOnDropShapeLife } = useOnDropShapeLife()
  const textAction = useSelector(selectTextAction)
  const targetId = useSelector(selectTargetId)

  const { svgJson } = useAlmagest()
  const onDropSourceToEditorCreator = useOnDropSourceToEditorCreator()
  const { remove, add } = useUpdateDocument()

  const [pathfyMutation] = usePathfyMutation({ variables: { text: '' } })

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)
  const fontSizeRef = React.useRef<HTMLSelectElement>(null)
  const fontFamiliesRef = React.useRef<HTMLSelectElement>(null)
  const alignRef = React.useRef<HTMLSelectElement>(null)
  const submitRef = React.useRef<HTMLButtonElement>(null)

  const waitingResponse = useWaitingResponse()

  const old =
    textAction !== 'add'
      ? svgJson.children.find((child) => child.attributes.id === targetId)
      : null

  const oldFontOption = old?.almagest.fontOption || {
    body: '',
    fontFamilies: 'M-PLUS-1p-Regular',
    fontSizes: 72,
  }

  const handleChange = () => {
    const isDisabled = !textAreaRef?.current?.value
    const submitClacc = classAccessor(submitRef)('-disabled')
    submitClacc[isDisabled ? 'add' : 'remove']()
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const trimmedText = (textAreaRef?.current?.value + '').trim()
    if (trimmedText === '') return

    // モーダル隠してオバーレイを通信中に
    waitingResponse.start(hideAllModal)

    const res = await pathfyMutation({
      variables: {
        text: trimmedText,
        font: fontFamiliesRef?.current?.value || 'M-PLUS-1p-Regular',
        size: +(fontSizeRef?.current?.value || 72),
        align: aligns[alignRef?.current?.value || 'left'] as Align,
      },
    })

    // オーバーレイを通信中解除して隠す
    waitingResponse.end()

    const svg = res.data?.pathfy.path as string
    if (!svg) return

    textAction === 'add' ? textAdd(svg) : textChange(svg)
  }

  const textAdd = (svg) => {
    // ペーパーがマウスクリックされた時に座標受け取ってaddする
    const onDropSourceToEditor = onDropSourceToEditorCreator.text(svg)
    startOnDropShapeLife(onDropSourceToEditor)
  }

  const textChange = (svg) => {
    const parseSvg = JSON.parse(svg)

    parseSvg.attributes.x = old?.attributes?.x || 100
    parseSvg.attributes.y = old?.attributes?.y || 100

    const rotate =
      +old?.children[0]?.attributes.transform
        ?.match(/rotate\(.*?\)/g)[0]
        .replace(/.*\(|\).*/g, '')
        .replace(/\,\s|\,/g, ' ')
        .split(' ')[0] || 0

    parseSvg.children[0].attributes.transform = `rotate(${rotate})`

    parseSvg.almagest.shapeType = 'text'
    parseSvg.almagest.positionsInit = {
      srcX: +parseSvg.attributes.x,
      srcY: +parseSvg.attributes.y,
      dstX: +parseSvg.attributes.x + parseSvg.attributes.width,
      dstY: +parseSvg.attributes.y + parseSvg.attributes.height,
    }
    remove(targetId)
    add(parseSvg)
  }

  return (
    <ModalAddOutline
      title={textAction === 'add' ? '文字を追加' : '文字を変更'}
      mainClassName="modalAddText"
      isActive={isActive}
      content={({ contentClassName }) => (
        <div className={contentClassName}>
          <div className="modalContent">
            <form onSubmit={handleSubmit}>
              <div className="modalContent__body">
                <div className="modalAddText">
                  <p className="modalAddText__label">内容</p>
                  <div className="modalAddText__text">
                    <textarea
                      name="text"
                      className="textarea"
                      placeholder="テキスト"
                      autoComplete="off"
                      ref={textAreaRef}
                      onChange={handleChange}
                      defaultValue={oldFontOption.body}
                    />
                  </div>

                  <dl className="modalAddText__options">
                    <div className="modalAddText__option">
                      <dt className="modalAddText__label">書体</dt>
                      <dd>
                        <label className="select -full">
                          <select
                            ref={fontFamiliesRef}
                            defaultValue={oldFontOption.fontFamily}
                          >
                            {Object.keys(fontFamilies).map((font, i) => (
                              <option value={font} key={i}>
                                {fontFamilies[font]}
                              </option>
                            ))}
                          </select>
                        </label>
                      </dd>
                    </div>

                    <div className="hidden">
                      <select ref={alignRef}>
                        <option value="left">左揃え</option>
                        <option value="center">中央揃え</option>
                        <option value="right">右揃え</option>
                      </select>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="modalContent__foot">
                <div className="btnList">
                  <ModalCloseBtn />
                  <button
                    className={`btn -primary -full ${
                      textAction === 'add' ? '-disabled' : ''
                    }`}
                    type="submit"
                    ref={submitRef}
                  >
                    {textAction === 'add' ? '文字を追加する' : '文字を変更する'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    />
  )
}

export default ModalAddDrawingText
