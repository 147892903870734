import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useOnDropShapeLife } from '~/hooks/onDropShapeLife'
import { hideAllModalAndOverlay, selectModalState } from '~/modules/modals'

/*
開発用に残すドラッグパレットと
共通化しようと思ってるけど
触るの久々すぎて結構忘れてるので
ダイアログ実装を優先させてコピペ展開
後日ちゃんとビジロジを搾り取る
*/

type Props = {
  title: string
  onDropSourceToEditor: any
}

const ClickSourceDrawing: React.FC<Props> = ({
  children,
  title,
  onDropSourceToEditor,
}) => {
  const dispatch = useDispatch()

  const { startOnDropShapeLife } = useOnDropShapeLife()

  const sourceRef = React.useRef<HTMLDivElement>(null)

  const clickTargetRef = React.useRef<HTMLDivElement>(null)

  return (
    <div ref={sourceRef} className="presetElement" data-title={title}>
      <div
        className="presetElement__thumbnail"
        ref={clickTargetRef}
        onClick={() => {
          startOnDropShapeLife(onDropSourceToEditor)
          dispatch(hideAllModalAndOverlay())
        }}
      >
        {children}
      </div>
      <figcaption className="presetElement__label">{title}</figcaption>
    </div>
  )
}
export default ClickSourceDrawing
