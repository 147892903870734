// byenvに移すべきサブコンポーネント有無を要確認
import * as React from 'react'
import { useUpdateDocument } from '~/hooks/updateDocument'
import { usePathfyMutation } from '~/hooks/apolloAction'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

// モーダル＋クリックドロップが成功したので、ここのロジックはあんまりもう改修しない

const fontFamilies = {
  'MPLUS1p-Regular': 'ゴシック',
  'LT-MatisseN-M': '明朝',
  'MPLUS1p-Bold': '太字ゴシック',
  'MPLUSRounded1c-Regular': '丸字ゴシック',
  'LT-PopHappiness-EB': 'ポップ',
  'LT-Klee-M': 'ペン字',
}

const fontSizes = {
  '72': 72,
  '82': 82,
}

const aligns = {
  left: 'LEFT',
  center: 'CENTER',
  right: 'RIGHT',
}

const PaletteTextInput: React.FC = () => {
  const { add } = useUpdateDocument()

  const [pathfyMutation] = usePathfyMutation({ variables: { text: '' } })

  const [text, setText] = React.useState<string>('')
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault()
    // trimするか要検討
    setText(event.target.value)
  }

  const [fontSize, setFontSize] = React.useState<number>()
  const [fontFamily, setFontFamily] = React.useState<string>('')
  const [align, setAlign] = React.useState<string>('')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const res = await pathfyMutation({
      variables: {
        text: text.trim(),
        font: fontFamily || 'MPLUS1p-Regular',
        size: fontSize || 72,
        align: aligns[align] || aligns.left,
      },
    })
    const svg = res.data?.pathfy.path as string
    if (!svg) return

    const textSvg = JSON.parse(svg)

    const width = textSvg.attributes.width as number
    const height = textSvg.attributes.height as number

    textSvg.attributes = {
      ...textSvg.attributes,
      x: 100,
      y: 100,
    }

    textSvg.almagest = {
      ...textSvg.almagest,
      shapeType: 'text',
      positionsInit: {
        srcX: 100,
        srcY: 100,
        dstX: 100 + +width,
        dstY: 100 + +height,
      },
    }

    add(textSvg)

    setText(text.trim())
  }

  return (
    <div className="palette">
      <form onSubmit={handleSubmit}>
        <textarea
          name="text"
          className={switcss({
            designed: '',
            framework:
              'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
            useFramework: true,
          })}
          placeholder="テキスト"
          autoComplete="off"
          value={text}
          onChange={handleChange}
        />

        <div>
          <select onChange={(event) => setFontSize(+event.target.value)}>
            {Object.keys(fontSizes).map((size, i) => (
              <option value={size} key={i}>
                {fontSizes[size + '']}
              </option>
            ))}
          </select>
        </div>

        <div>
          <select onChange={(event) => setFontFamily(event.target.value)}>
            {Object.keys(fontFamilies).map((font, i) => (
              <option value={font} key={i}>
                {fontFamilies[font]}
              </option>
            ))}
          </select>
        </div>

        <div>
          <select onChange={(event) => setAlign(event.target.value)}>
            <option value="left">左揃え</option>
            <option value="center">中央揃え</option>
            <option value="right">右揃え</option>
          </select>
        </div>

        <button
          className={switcss({
            designed: '',
            framework:
              'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4',
            useFramework: true,
          })}
          type="submit"
        >
          配置
        </button>
      </form>
    </div>
  )
}
export default PaletteTextInput
