// config廃止すると何なのかよく分からなく見えるけど、原型は
/*
const isUsingBrowserContextMenu = () => {
  if (isProduction) return false
  return isMacCtrlToggleOn
}
*/
export const isUsingBrowserContextMenu = (
  isMacCtrlToggleOn: boolean
): boolean => isMacCtrlToggleOn
