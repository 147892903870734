import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useAlmagest,
  historiesModule,
  targetModule,
  inputDeviceModule,
} from '@naire-seisakusho/react-almagest'

import { useEventListener } from '~/hooks/eventListener'
import { useLogger } from '~/hooks/logger'
import { useUpdateDocument } from '~/hooks/updateDocument'
import { useSpica } from '~/context'
import { setMacCtrlToggleOn, selectManeuverState } from '~/modules/maneuver'
import { useOnDropShapeLife } from '~/hooks/onDropShapeLife'
import { selectModalState } from '~/modules/modals'
import {
  setDoingHotKeyTaskRemove,
  setDoingHotKeyTaskUndo,
  setDoingHotKeyTaskRedo,
  setDoingHotKeyTaskCopy,
  setDoingHotKeyTaskCut,
  setDoingHotKeyTaskPaste,
  selectDoingHotKeyTasksState,
} from '~/modules/doingHotKeyTasks'

// import type {Operation} from '~/hooks/logger'

const { selectTargetId } = targetModule
const { undo: historyUndo, redo: historyRedo } = historiesModule
const { setCtrlCmd, setAltOpt, setShift, setMacCtrl } = inputDeviceModule

export const useKeyEvents = () => {
  const dispatch = useDispatch()
  const { endOnDropShapeLife } = useOnDropShapeLife()
  const targetId = useSelector(selectTargetId)

  const { afterCommit } = useAlmagest()
  const { writeLog } = useLogger()
  const updateDocument = useUpdateDocument()
  const maneuverState = useSelector(selectManeuverState)
  const modalState = useSelector(selectModalState)
  const doingHotKeyTasksState = useSelector(selectDoingHotKeyTasksState)

  const keyStatus = React.useRef({
    ctrlCmd: false,
    altOpt: false,
    shift: false,
    // macのctrlは開発時以外使わない(イベント利用する側で制御して)
    macCtrl: false,
  })

  // const keydownMacCtrl = () => {
  //   pressStatus.key.macCtrl = false
  //   setPressStatus({ ...pressStatus })
  // }

  // キー操作
  // iPadを考慮して like mac で判定 Linuxは多分winと同じ
  const isMac = window.navigator.userAgent.toLowerCase().match('mac')

  const keyNames = { ctrl: 'Control', meta: 'Meta', shift: 'Shift', alt: 'Alt' }

  const hasFocusInput = event =>
    [HTMLInputElement, HTMLTextAreaElement].some(
      ElementType => event.target instanceof ElementType
    )

  const keyState = (() => {
    const core = (event: KeyboardEvent, isDown = false) => {
      if (!Object.values(keyNames).includes(event.key)) return

      if (event.key === (isMac ? keyNames.meta : keyNames.ctrl)) {
        keyStatus.current.ctrlCmd = isDown
        dispatch(setCtrlCmd(isDown))
      }

      if (event.key === keyNames.alt) {
        keyStatus.current.altOpt = isDown
        dispatch(setAltOpt(isDown))
      }

      if (event.key === keyNames.shift) {
        keyStatus.current.shift = isDown
        dispatch(setShift(isDown))
      }

      if (!hasFocusInput(event) && isMac && event.key === keyNames.ctrl) {
        keyStatus.current.macCtrl = isDown
        dispatch(setMacCtrl(isDown))
        if (isDown)
          dispatch(setMacCtrlToggleOn(!maneuverState.isMacCtrlToggleOn))
      }
    }

    return {
      keydown: (event: KeyboardEvent) => core(event, true),
      keyup: (event: KeyboardEvent) => core(event, false),
    }
  })()

  const doingKeyTasks = {
    paste: false,
  }

  useEventListener<KeyboardEvent>('keydown', event => {
    keyState.keydown(event)

    const doKeyActionWhenNoMouse = (
      hotKeyTaskName: string,
      hotKeyTask: () => void,
      doingHotKeyTaskReducer: (isDoing: boolean) => void
    ) => {
      // if (Object.values(pressStatus.mouse).some(isPressing => isPressing))
      //   return
      // event.preventDefault()

      if (doingHotKeyTasksState[hotKeyTaskName]) return
      dispatch(doingHotKeyTaskReducer(true))
      hotKeyTask()
      setTimeout(() => {
        dispatch(doingHotKeyTaskReducer(false))
      }, 200)
    }

    const undo = () => {
      event.preventDefault()

      doKeyActionWhenNoMouse(
        'undo',
        () => dispatch(historyUndo({ callback: afterCommit })),
        setDoingHotKeyTaskUndo
      )
      // TODO: 動作したか確認してからにする
      // writeLog({ action: Operation.Undo })
    }
    const redo = () => {
      event.preventDefault()

      doKeyActionWhenNoMouse(
        'redo',
        () => dispatch(historyRedo({ callback: afterCommit })),
        setDoingHotKeyTaskRedo
      )
      // writeLog({ action: Operation.Redo })
    }

    const copy = () => {
      event.preventDefault()

      doKeyActionWhenNoMouse(
        'copy',
        () => updateDocument.copy(targetId),
        setDoingHotKeyTaskCopy
      )
      // writeLog({ action: Operation.Redo })
    }
    const cut = () => {
      event.preventDefault()

      doKeyActionWhenNoMouse(
        'cut',
        () => updateDocument.cut(targetId),
        setDoingHotKeyTaskCut
      )
      // writeLog({ action: Operation.Redo })
    }
    const paste = () => {
      event.preventDefault()

      doKeyActionWhenNoMouse(
        'paste',
        () => updateDocument.paste(),
        setDoingHotKeyTaskPaste
      )
      // writeLog({ action: Operation.Redo })
    }
    const remove = () => {
      if (!targetId) return

      event.preventDefault()

      doKeyActionWhenNoMouse(
        'remove',
        () => updateDocument.remove(targetId),
        setDoingHotKeyTaskRemove
      )
      // writeLog({ action: Operation.Redo })
    }

    if (
      !hasFocusInput(event) &&
      !modalState.isShowingOverlay &&
      !maneuverState.onDropShape &&
      !maneuverState.isShowingColorPicker
    ) {
      if (event.key === 'Backspace') remove()

      if (keyStatus.current.ctrlCmd) {
        if (event.key === 'z') undo()
        if (event.key === 'y') redo()
        if (event.key === 'c') copy()
        if (event.key === 'x') cut()
        if (event.key === 'v') paste()
      }
    }

    // ドロップ待機時のEscape
    if (maneuverState.onDropShape && event.key.toLowerCase() === 'escape') {
      endOnDropShapeLife()
    }

    // // ⌘⇧z
    // if (isMac && pressStatus.key.ctrlCmd && pressStatus.key.shift) {
    //   if (event.key === 'z') redo()
    // }
  })

  useEventListener<KeyboardEvent>('keyup', event => keyState.keyup(event))
}
