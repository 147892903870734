import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ModalCloseBtn } from '~/components/atoms'
import { ModalAddOutline } from '~/components/molecules'
import {
  ShapeTypes,
  ShapeTypeNameDefines,
} from '@naire-seisakusho/react-almagest'
import { useOnDropShapeLife } from '~/hooks/onDropShapeLife'
import { hideAllModalAndOverlay, selectModalState } from '~/modules/modals'
import { MaterialTypes } from '~/dedicated/materialTypes'
import { materialList } from '~/dedicated/materialList'
import { useOnDropSourceToEditorCreator } from '~/hooks/onDropSourceToEditorCreator'
import { baseUrl } from '~/utils/baseUrl'

type Props = {
  isActive: boolean
}
// kkbizが思ってるHOCと違う感じになった・・・
const ModalAddMaterial: React.FC<Props> = ({ isActive }) => {
  const onDropSourceToEditorCreator = useOnDropSourceToEditorCreator()
  const dispatch = useDispatch()
  const { startOnDropShapeLife } = useOnDropShapeLife()

  const onDropSourceToEditorCreators = {
    [MaterialTypes.svgImage]: (src) => onDropSourceToEditorCreator.preset(src),
    [MaterialTypes.backgroundImage]: (src) =>
      onDropSourceToEditorCreator.background(src),
    [MaterialTypes.partsImage]: (src) =>
      onDropSourceToEditorCreator.image(src, 500),
  }

  return (
    <ModalAddOutline
      title="素材を追加"
      mainClassName="modalAddPreset"
      isActive={isActive}
      content={({ contentClassName }) => (
        <div className={contentClassName}>
          <div className="modalContent -overflow">
            <div className="modalContent__body">
              <ul className="materialList">
                {materialList.map((materialItem) => (
                  <li key={materialItem.name}>
                    <figure
                      className="materialItem"
                      onClick={() => {
                        startOnDropShapeLife(
                          onDropSourceToEditorCreators[materialItem.type](
                            baseUrl + materialItem.url.image
                          )
                        )
                        dispatch(hideAllModalAndOverlay())
                      }}
                    >
                      <img
                        src={baseUrl + materialItem.url.thumbnail}
                        alt="bgItem.name"
                      />
                      <figcaption className="materialItem__label">
                        {materialItem.name}
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modalContent__foot">
              <div className="btnList">
                <ModalCloseBtn />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default ModalAddMaterial
