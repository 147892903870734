import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  ShapeTypes,
  ShapeTypeNameDefines,
} from '@naire-seisakusho/react-almagest'

import { showOverlay } from '~/modules/modals'
import { useWaitingResponse } from '~/hooks/waitingResponse'
import { useOnDropShapeLife } from '~/hooks/onDropShapeLife'
import { setUploadedImages } from '~/modules/uploadedImages'
import { useUploadPhotoMutation } from '~/hooks/apolloAction'
import { useOnDropSourceToEditorCreator } from '~/hooks/onDropSourceToEditorCreator'
import { baseUrl } from '~/utils/baseUrl'

export const useUploadImage = (): any => {
  const dispatch = useDispatch()
  const { startOnDropShapeLife } = useOnDropShapeLife()
  const onDropSourceToEditorCreator = useOnDropSourceToEditorCreator()

  const { frontToken: token } = useParams() as { frontToken: string }

  const [uploadPhotoMutation, uploadPhoto] = useUploadPhotoMutation()

  const waitingResponse = useWaitingResponse()

  const uploadFileRef = React.useRef<HTMLInputElement>(null)

  const validate = (base64: string) => {
    const extension = base64.match(/[^:/]\w+(?=;|,)/)?.shift() + ''
    const size = base64.length * (3 / 4)

    const error: string[] = []
    if (!['jpeg', 'png', 'gif'].includes(extension))
      error.push('画像以外のファイルです')

    // 10MB
    if (size > 10 * 1024 * 1024) error.push('ファイルサイズが大きすぎます')

    return error
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0]

    if (uploadFileRef.current?.value) uploadFileRef.current.value = ''

    const reader = new FileReader()
    reader.onload = async (filerender) => {
      const error = validate(filerender.target?.result as string)

      if (error.length > 0) return

      // モーダル隠してオバーレイを通信中に
      waitingResponse.start(showOverlay)

      await uploadPhotoMutation({
        variables: { token, file: filerender.target?.result as string },
      })

      // オーバーレイを通信中解除して隠す
      waitingResponse.end()
    }
    reader.readAsDataURL(file)
  }

  React.useEffect(() => {
    if (!uploadPhoto.data?.uploadPhoto?.length) return

    dispatch(
      setUploadedImages(uploadPhoto.data?.uploadPhoto?.map((item) => item.url))
    )

    const src = uploadPhoto.data?.uploadPhoto?.pop()?.url
    const tokenizedUrl = `${baseUrl}/photos/${token}/${src}`

    // console.log(tokenizedUrl)

    const onDropSourceToEditor = onDropSourceToEditorCreator.image(tokenizedUrl)

    startOnDropShapeLife(onDropSourceToEditor)
  }, [uploadPhoto.data?.uploadPhoto])

  return { uploadFileRef, handleFileSelect }
}
