import React from 'react'
import { useSelector } from 'react-redux'
import { targetModule, useAlmagest } from '@naire-seisakusho/react-almagest'

import { useUpdateDocument } from '~/hooks/updateDocument'

import { EditorElementsNavBtn } from '~/components/atoms'

const { selectTargetId } = targetModule

type Props = {}
const EditorElementsNav: React.FC<Props> = () => {
  const targetId = useSelector(selectTargetId)
  const updateDocument = useUpdateDocument()
  const { svgJson } = useAlmagest()
  let isLayerHead = true
  let isLayerTail = true
  if (svgJson && svgJson.children) {
    const index = svgJson.children.findIndex(
      (child) => child.attributes.id === targetId
    )
    // 奥・手前の方向と配列の順番の感覚が逆なのはこの代入時点で解消しておく
    isLayerHead = index === svgJson.children.length - 1
    isLayerTail = index === 0
  }

  return (
    <dl className="editorElementNav">
      {/*
      <dt className="editorElementNav__title">整列</dt>
      <dd>
        <EditorElementsNavBtn icon="sort-left" />
      </dd>
      <dd>
        <EditorElementsNavBtn icon="sort-center" />
      </dd>
      <dd>
        <EditorElementsNavBtn icon="sort-right" />
      </dd>
      <dd>
        <EditorElementsNavBtn icon="sort-top" />
      </dd>
      <dd>
        <EditorElementsNavBtn icon="sort-middle" />
      </dd>
      <dd>
        <EditorElementsNavBtn icon="sort-bottom" />
      </dd>
      */}

      <dt className={`editorElementNav__title ${!targetId ? '-disabled' : ''}`}>
        複製
      </dt>
      <dd>
        <EditorElementsNavBtn
          icon="duplicate"
          onClick={() => updateDocument.clone(targetId)}
          isDisabled={!targetId}
        />
      </dd>

      <dt className={`editorElementNav__title ${!targetId ? '-disabled' : ''}`}>
        重ね順
      </dt>
      <dd>
        <EditorElementsNavBtn
          icon="layer-up"
          onClick={() => updateDocument.bringForward(targetId)}
          isDisabled={!targetId || isLayerHead}
        />
        <EditorElementsNavBtn
          icon="layer-down"
          onClick={() => updateDocument.sendBackward(targetId)}
          isDisabled={!targetId || isLayerTail}
        />
      </dd>

      <dt className={`editorElementNav__title ${!targetId ? '-disabled' : ''}`}>
        削除
      </dt>
      <dd>
        <EditorElementsNavBtn
          icon="delete"
          onClick={() => updateDocument.remove(targetId)}
          isDisabled={!targetId}
        />
      </dd>
    </dl>
  )
}

export default EditorElementsNav
