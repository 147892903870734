import { useSelector, useDispatch } from 'react-redux'
import {
  showOverlay,
  hideOverlay,
  startWaitingResponse,
  endWaitingResponse,
  selectModalState,
} from '~/modules/modals'

export const useDevHash = () => {
  const dispatch = useDispatch()
  const hash = location.hash.replace(/^#/, '')
  if (hash === '') return
  const devHashes = {
    showOverlay: {
      log: 'オーバーレイを表示',
      func: () => dispatch(showOverlay()),
    },
    startWaitingResponse: {
      log: 'オーバーレイをローディング表示',
      func: () => {
        dispatch(showOverlay())
        dispatch(startWaitingResponse())
      },
    },
    hideOverlay: {
      log:
        'オーバーレイとローディングを非表示(コンソールにエラーが出ますが大丈夫です)',
      func: () => {
        dispatch(hideOverlay())
        dispatch(endWaitingResponse())
        location.hash = ''
      },
    },
  }
  const devHash = devHashes[hash]
  devHash.func()
}
/* 対応ブックマークレット
javascript: (function() {
  var hash = location.hash.replace(/^#/, '');
  if (hash === '') location.hash = 'showOverlay';
  if (hash === 'showOverlay') location.hash = 'startWaitingResponse';
  if (hash === 'startWaitingResponse') location.hash = 'hideOverlay';
})();
*/
