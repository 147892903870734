import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash-es'
import { ReactSortable } from 'react-sortablejs-typescript'
import {
  useAlmagest,
  historiesModule,
  targetModule,
} from '@naire-seisakusho/react-almagest'

import { Layer } from '~/components/molecules'
import { classAccessor } from '~/utils/classAccessor'

const { setId, clear, selectTargetId } = targetModule
const { add } = historiesModule

const Layers: React.FC = () => {
  const dispatch = useDispatch()
  const targetId = useSelector(selectTargetId)

  const { svgJson, afterCommit, attachment } = useAlmagest()

  const [doReverse, setDoReverse] = React.useState<boolean>(true)
  const [isReversing, setIsReversing] = React.useState<boolean>(false)

  const layerListRef = React.useRef<HTMLDivElement>(null)

  if (!svgJson) return null

  const svgAttributes = svgJson.attributes

  const nodes = cloneDeep(svgJson.children)

  const byReversal = (nodes: any) => (doReverse ? nodes.reverse() : nodes)

  const setNodes = (newNodes: any) => {
    const tmpTargetId = targetId

    const tmp = cloneDeep(svgJson)
    tmp.children = byReversal(newNodes)

    dispatch(clear())
    dispatch(add({ document: tmp, target: tmpTargetId, callback: afterCommit }))
    dispatch(setId(tmpTargetId))
  }

  const toggleSort = () => {
    if (isReversing) return
    const doReversePre = doReverse
    setDoReverse(!doReverse)
    if (!layerListRef.current?.children) return
    setIsReversing(true)
    const layerListCollection = layerListRef.current?.children[0].children
    const layerList = Array.from(layerListCollection)
    // これ今呼ばれないのでponyfill後は未テスト
    layerList.forEach((layer) => {
      classAccessor(layer)('hiding').add()
    })
    if (!doReverse || doReversePre) layerList.reverse()
    Array.from(layerList).forEach((layer, i) => {
      // これ今呼ばれないのでponyfill後は未テスト
      const clacc = classAccessor(layer)
      const appearingClacc = clacc('appearing')
      const hidingClacc = clacc('hiding')
      setTimeout(() => {
        appearingClacc.add()
        hidingClacc.remove()
        setTimeout(() => {
          appearingClacc.remove()
          if (i === layerList.length - 1) setIsReversing(false)
        }, 200)
      }, i * 100)
    })
  }

  const [isDragging, setIsDragging] = React.useState<boolean>(false)

  return (
    <div className="layers">
      {/*

      <button
        className={`reverse-order ${isReversing ? 'disabled' : ''}`}
        onClick={toggleSort}
      >
        上下入替(命名難しい)
      </button>
      <span className="order-guide">↑{doReverse ? '手前' : '奥'}</span>
      */}
      <div className="layers__attention">
        <p className="sideContentAttention">
          印刷ガイド線やグリッド線、うちわ骨の表示・非表示を切り替えることができます。
        </p>
      </div>
      <div className={'layers__content'} ref={layerListRef}>
        <ReactSortable
          list={nodes}
          setList={setNodes}
          className={`layers__list ${isDragging ? 'sorting' : ''}`}
          chosenClass="-chosen"
          ghostClass="-ghost"
          dragClass="-dragging"
          onStart={(e) => setIsDragging(true)}
          onEnd={(e) => setIsDragging(false)}
        >
          {byReversal(nodes).map((node, i) => (
            <Layer
              svgAttributes={svgAttributes}
              item={node}
              guide={attachment.guideSvg as Element}
              index={i}
              active={node.attributes.id === targetId}
              onClick={() => dispatch(setId(node.attributes.id))}
              key={node.attributes.id}
            />
          ))}
        </ReactSortable>
      </div>
      {/*
      <span className="order-guide">↓{doReverse ? '奥' : '手前'}</span>
      */}
    </div>
  )
}

export default Layers
