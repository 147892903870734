import * as React from 'react'
import { useDispatch } from 'react-redux'
import { hideAllModalAndOverlay } from '~/modules/modals'
import { Button } from '~/components/atoms'
type Props = {
  text?: string
  type?: string
  size?: string
}
const ModalCloseBtn: React.FC<Props> = ({
  text = 'キャンセル',
  type,
  size,
}) => {
  const dispatch = useDispatch()
  return (
    <Button
      text={text}
      size={size}
      type={type}
      onClick={() => dispatch(hideAllModalAndOverlay())}
      full={true}
    />
  )
}
export default ModalCloseBtn
