export const classList = (dom: any) => {
  // このponyfillは本物より気が利いているように作るのはやめておこう
  if (!dom.attributes) return undefined
  const setClassList = (callback: (classNames: string[]) => string[]) => {
    const classAttribute = dom.getAttribute('class')
    const currentClassList = classAttribute ? classAttribute.split(' ') : []
    dom.setAttribute('class', callback(currentClassList).join(' '))
  }
  if (dom.classList)
    return {
      add: (...classNames: string[]) => dom.classList.add(...classNames),
      remove: (...classNames: string[]) => dom.classList.remove(...classNames),
    }
  return {
    add: (...classNames: string[]) =>
      setClassList((currentClassList) =>
        currentClassList.concat(
          classNames.filter(
            (className) => !currentClassList.includes(className)
          )
        )
      ),
    remove: (...classNames: string[]) =>
      setClassList((currentClassList) =>
        currentClassList.filter((className) => !classNames.includes(className))
      ),
  }
}

// datasetのキャメライズ仕様に準拠(どっかからコピった)
const toCamelCaseAlongDataset = (str: string) => {
  const matched = str.match(/^data-(.+)/)
  if (!matched) return str
  return matched[1].replace(/-([a-z])/gi, (_: string, letter: string) =>
    letter.toUpperCase()
  )
}

// // 「ハイフンと小文字の組み合わせ」が大文字になる
// console.log(toCamelCase('data-num-to-str'));
// //  numToStr
// console.log(toCamelCase('data-num-2-str'));
// //  num-2Str
// console.log(toCamelCase('data-num2-str'));
// //  num2Str
// console.log(toCamelCase('data-num-2str'));
// //  num-2str

export const dataset = (dom: any) => {
  // このponyfillは本物より気が利いているように作るのはやめておこう
  if (!dom.attributes) return undefined
  if (dom.dataset) return dom.dataset
  const dataAttributeNames = Object.values(dom.attributes)
    .filter((node: any) => node.name.startsWith('data'))
    .map((node: any) => node.name)
  // datasetに合わせてjsonのプロパティに見せかけてるけどただのgetter/setter
  const papier = {}
  dataAttributeNames.forEach((dataAttributeName) => {
    const camelName = toCamelCaseAlongDataset(dataAttributeName)
    Object.defineProperty(papier, camelName, {
      get: () => dom.getAttribute(dataAttributeName),
      set: (newValue) => dom.setAttribute(dataAttributeName, newValue),
    })
  })
  return papier
}

export const getBoundingClientRect = (dom: any) => {
  const bcRect = dom.getBoundingClientRect()
  if (bcRect.x !== undefined && bcRect.y !== undefined) return bcRect
  // definePropertyでreadonlyにしようと思ったけどモダブラでwritableプロパティになってからいいや
  bcRect.x = bcRect.left
  bcRect.y = bcRect.top
  return bcRect
}
