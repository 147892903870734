import * as React from 'react'
import { Link, useParams } from 'react-router-dom'

import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const Header: React.FC = () => {
  const { frontToken, backToken } = useParams()

  return (
    <div className="header">
      <a className="ci" href="https://uchiwa.lestas.jp/">
        <img src="/img/logo-uchiwa.png" alt="オリジナルうちわ製作所" />
      </a>

      <address className="telephone">
        <a className="telephone__number" href="tel:120-054-052">
          0120-054-052
        </a>
        <span className="telephone__notice">9:30〜18:30 (土日祝休)</span>
      </address>
    </div>
  )
}

export default Header
