// type系エラーよく分からん(@type/whatwg-fetch入れてもまだ分からん)のであとよろです
// FIXME: unfetch
// import { fetch } from 'whatwg-fetch'
// if (!window.fetch) window.fetch = fetch
// import { fetch } from 'whatwg-fetch'
// import 'isomorphic-unfetch'

/*
SCRIPT5022:
fetch is not found globally and no fetcher passed, to fix pass a fetch for
your environment like https://www.npmjs.com/package/unfetch.
For example:
*/
// import fetch from 'unfetch'
// import { createHttpLink } from 'apollo-link-http';
// const link = createHttpLink({ uri: '/graphql', fetch: fetch });

import React from 'react'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import App from './app'

// console.log(document.referrer)
import Promise from 'core-js/stable/promise'

// Sentry.init({
//   dsn: 'http://7c362fbf2a00494d9850a22d40118b98@34.84.118.4:9000/14',
// })

import { byenv } from '~/byenv/'
const {
  utils: { sentry },
} = byenv

// developmentの時だけsentryしない
sentry()

if (!window.Promise) window.Promise = Promise

ReactDOM.render(<App />, document.getElementById('root'))

// Sentry使うならaddListenerじゃなくてonHandler上書きしてるのは危なそうなのでとりあえずやめ
// window.onerror = function(errorMsg, fileName, lineNumber) {
//   const errorInfo = {
//     errorMsg: errorMsg, // エラーメッセージ
//     fileName: fileName, // エラーが発生したスクリプトのファイル名
//     lineNumber: lineNumber, // エラーが発生した行
//     urlDispPage: location.href, // エラー発生時に閲覧していた URL
//     userAgent: navigator.userAgent, // エラーが発生したクライアントのユーザエージェント
//   }
//   console.log(errorInfo)
// }
