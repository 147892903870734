import * as React from 'react'

type Props = {}
const Loading: React.FC<Props> = () => (
  <div className="loading-cube-grid">
    <div className="loading-cube loading-cube1"></div>
    <div className="loading-cube loading-cube2"></div>
    <div className="loading-cube loading-cube3"></div>
    <div className="loading-cube loading-cube4"></div>
    <div className="loading-cube loading-cube5"></div>
    <div className="loading-cube loading-cube6"></div>
    <div className="loading-cube loading-cube7"></div>
    <div className="loading-cube loading-cube8"></div>
    <div className="loading-cube loading-cube9"></div>
  </div>
)

export default Loading
