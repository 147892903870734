import * as React from 'react'
import { ModalAddDrawingCore } from '~/components/molecules'
import { ShapeTypes } from '@naire-seisakusho/react-almagest'
import type { OnPaletteDefines } from '~/hooks/onPaletteSourceDrawing'

type Props = {
  isActive: boolean
}

const droppedInitSizes = {
  width: 250,
  height: 250,
}

const drawedAttributes = {
  fill: '#777',
  stroke: '#fff',
}

const onPaletteDefines: OnPaletteDefines = {
  rect: {
    shapeType: ShapeTypes.rect,
    title: '矩形',
    droppedInitSizes,
    drawedAttributes,
  },
  ellipse: {
    shapeType: ShapeTypes.ellipse,
    title: '円',
    droppedInitSizes,
    drawedAttributes,
  },
  triangleIsosceles: {
    shapeType: ShapeTypes.triangleIsosceles,
    title: '二等辺三角形',
    droppedInitSizes,
    drawedAttributes,
  },
}

const sizeOnPaletteShorterSide = 50

// 僕が思ってるHOCと違う感じになった・・・
const ModalAddDrawingShape: React.FC<Props> = ({ isActive }) =>
  ModalAddDrawingCore({
    title: '図形を追加',
    mainClassName: 'modalAddDrawingShape',
    onPaletteDefines, // この中で定義されてるのはドロップ後の縦横
    sizeOnPaletteShorterSide, // この数値はパレットでの短辺の長さ
    isActive,
  })

export default ModalAddDrawingShape
