import React from 'react'

import {
  Header,
  Sidebar,
  Editor,
  NavBar,
  EditorElementsNav,
  Modals,
  ModalConfirm,
  ModalAttention,
  ModalOverlay,
  FaceThumbnailList,
} from '~/components/organisms'

import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

import { byenv } from '~/byenv'
const {
  components: { IntraController },
} = byenv

const HomeTemplate: React.FC = () => {
  return (
    <div
      // このid使います
      id="home"
      className={switcss({
        designed: 'home',
        framework: 'h-screen',
        useFramework: false,
      })}
    >
      <div className="head">
        <Header />
      </div>
      <div className="body">
        <div className="menu">
          <NavBar />
        </div>

        <div
          className={switcss({
            designed: 'contents',
            framework: 'flex relative',
            useFramework: false,
          })}
        >
          <div
            className={switcss({
              designed: 'mainBlock',
              framework: 'w-3/4 h-screen',
              useFramework: false,
            })}
          >
            <div
              className={switcss({
                designed: 'leftNav',
                framework: 'absolute top-0 left-0 w-64 h-full',
                useFramework: false,
              })}
            >
              <FaceThumbnailList />
            </div>

            <div className="rightNav">
              <EditorElementsNav />
            </div>

            <div className="mainContent">
              <Editor />
            </div>
          </div>

          <div
            className={switcss({
              designed: 'sideBlock',
              framework: 'w-1/4 bg-gray-400',
              useFramework: false,
            })}
          >
            <Sidebar />
          </div>
        </div>
      </div>

      {IntraController && <IntraController />}

      <Modals />
      <ModalConfirm />
      <ModalAttention />
      <ModalOverlay />
    </div>
  )
}

export default HomeTemplate
