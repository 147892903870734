import svgson from 'svgson'
import { useUpdateDocument } from '~/hooks/updateDocument'
import { createSvgson } from '~/utils/createSvgson'
import {
  useAlmagest,
  ShapeTypes,
  ShapeTypeNameDefines,
  ShapeTypeOriginSizesToAttrbutes,
} from '@naire-seisakusho/react-almagest'
import {
  useToDroppedPositionSizes,
  maxSizeOnDroppedGeneral,
} from '~/hooks/toDroppedPositionSizes'

export const useOnDropSourceToEditorCreator = () => {
  const { add } = useUpdateDocument()
  const { toDroppedPositionSizes } = useToDroppedPositionSizes()
  const { almagest } = useAlmagest()

  const drawing = (shapeTypeOnPalette, onPaletteDefine, svgson) => ({
    key: shapeTypeOnPalette,
    ghostElement: null, // 今は空でいい(ghostちゃんとする布石)
    createElement: async ({ x, y }: { x: number; y: number }) => {
      const { shapeType, droppedInitSizes } = onPaletteDefine

      const { droppedPositions, droppedSizes } = toDroppedPositionSizes(
        { x, y },
        droppedInitSizes
      )

      const droppedAttributes = ShapeTypeOriginSizesToAttrbutes[shapeType]
        .origin(droppedPositions)
        .sizes(droppedSizes)

      svgson.attributes = { ...svgson.attributes, ...droppedAttributes }
      svgson.almagest = {
        shapeType: shapeType,
        // これまだ使われた事ないらしい
        positionsInit: {
          srcX: droppedPositions.x,
          srcY: droppedPositions.y,
          dstX: droppedPositions.x + droppedSizes.width, // これを上のやつと同じ変数に
          dstY: droppedPositions.y + droppedSizes.height, // これを
        },
      }

      add(svgson)
    },
  })

  const preset = (src) => ({
    key: src,
    ghostElement: null, // 今は空でいい(ghostちゃんとする布石)
    createElement: async ({ x, y }: { x: number; y: number }) => {
      // paletteのSVG用と同じなので統合予定

      const svg = await (await fetch(src)).text()
      const element = await (svgson as any).parse(svg)

      const width = element.attributes.width as number
      const height = element.attributes.height as number

      const { droppedPositions, droppedSizes } = toDroppedPositionSizes(
        { x, y },
        { width, height }
      )

      const attributes = {
        ...droppedPositions,
        ...droppedSizes,
      }

      element.attributes = {
        ...element.attributes,
        ...{
          x: attributes.x,
          y: attributes.y,
          width: attributes.width,
          height: attributes.height,
          viewBox: `0 0 ${attributes.width} ${attributes.height}`,
          overflow: 'auto',
        },
      }

      element.almagest = {
        shapeType: ShapeTypes.preset,
        positionsInit: {
          srcX: attributes.x,
          srcY: attributes.y,
          dstX: attributes.x + attributes.width,
          dstY: attributes.y + attributes.height,
        },
      }

      add(element)
    },
  })

  const imageCore = ({
    src,
    maxSizeOnDropped,
    positionSizesToAttributes,
    useAnimateSpawn = true,
    className = '', // このnullableハンドリングどうしたらいいかわからん
  }) => ({
    key: src,
    ghostElement: null, // 今は空でいい(ghostちゃんとする布石)
    createElement: async ({ x, y }: { x: number; y: number }) => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        const { width, height } = img

        const { droppedPositions, droppedSizes } = toDroppedPositionSizes(
          { x, y },
          { width, height },
          maxSizeOnDropped
        )

        const attributes = positionSizesToAttributes(
          droppedPositions,
          droppedSizes
        )

        const svgson = createSvgson
          .tag(ShapeTypeNameDefines[ShapeTypes.image].tag)
          .props({
            attributes: {
              // base64 で保存するため元のURLを残しておくため
              'data-original-href': src,
              href: src,
              x: attributes.x,
              y: attributes.y,
              width: attributes.width,
              height: attributes.height,
            },
            almagest: {
              shapeType: ShapeTypes.image,
              useAnimateSpawn,
              positionsInit: {
                srcX: attributes.x,
                srcY: attributes.y,
                dstX: attributes.x + attributes.width,
                dstY: attributes.y + attributes.height,
              },
            },
            children: [],
          })

        add(svgson)
      }
    },
  })

  const image = (src, maxSizeOnDropped = maxSizeOnDroppedGeneral) =>
    imageCore({
      src,
      maxSizeOnDropped,
      positionSizesToAttributes: (droppedPositions, droppedSizes) => ({
        ...droppedPositions,
        ...droppedSizes,
      }),
    })

  const getSpicaSightSizes = () => {
    const spicaSight = document.getElementById('sight') as any
    return {
      width: spicaSight.offsetWidth,
      height: spicaSight.offsetHeight,
    }
  }

  const background = (src) =>
    imageCore({
      src,
      // maxSizeOnDropped: getSpicaSightSizes()
      maxSizeOnDropped: almagest.sight,
      positionSizesToAttributes: (droppedPositions, droppedSizes) => ({
        x: (almagest.sight.width - droppedSizes.width) / 2,
        y: (almagest.sight.height - droppedSizes.height) / 2,
        ...droppedSizes,
      }),
      useAnimateSpawn: false,
    })

  const text = (svg) => ({
    key: new Date().getTime().toString(), // とりあえず(パレットからドラッグする時用なので)
    ghostElement: null, // 今は空でいい(ghostちゃんとする布石)
    createElement: async ({ x, y }: { x: number; y: number }) => {
      const textSvg = JSON.parse(svg)

      const width = textSvg.attributes.width as number
      const height = textSvg.attributes.height as number

      // この関数を実行する事で、クリックされたマウス座標を中心として
      // SVG要素の始点が計算される
      // droppedSizesは、実は関数の第3引数に最大サイズを指定(どでかい画像の
      // アップロード対策)できるようにしてあるので、その場合に最大サイズに馴らして
      // 縦横比を再計算したサイズが返却されたもの(最大サイズを指定しなければ、
      // 引数と同じサイズ)
      const { droppedPositions, droppedSizes } = toDroppedPositionSizes(
        { x, y },
        { width, height }
      )

      textSvg.attributes = {
        ...textSvg.attributes,
        width: droppedSizes.width, // 元のwidthを上書き
        height: droppedSizes.height, // 元のheightを上書き
        x: droppedPositions.x,
        y: droppedPositions.y,
      }

      textSvg.almagest = {
        ...textSvg.almagest,
        shapeType: 'text',
        positionsInit: {
          srcX: droppedPositions.x,
          srcY: droppedPositions.y,
          dstX: droppedPositions.x + droppedSizes.width,
          dstY: droppedPositions.y + droppedSizes.height,
        },
      }

      add(textSvg)
    },
  })

  return {
    drawing,
    image,
    preset,
    text,
    background,
  }
}
