import React from 'react'
import { useParams, Redirect } from 'react-router-dom'

import { ConfirmTemplate } from '~/components/templates'

const Confirm: React.FC = () => {
  const { frontToken, backToken } = useParams()

  if (!frontToken || !backToken) return <Redirect to={'/404'} />

  return <ConfirmTemplate />
}

export default Confirm
