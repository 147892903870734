import * as React from 'react'
import { useDispatch } from 'react-redux'

import { showModalConfirm } from '~/modules/modals'
import { Button } from '~/components/atoms'

import { switcss } from '~/utils/switcss'

type Props = {}

const PhaseNav: React.FC<Props> = ({}) => {
  const dispatch = useDispatch()

  return (
    <div className="phaseNav">
      <Button
        text='デザインを完了して次へ'
        type="conversion"
        onClick={() => dispatch(showModalConfirm())}
        full={true}
        size="xl"
      />
    </div>
  )
}
export default PhaseNav
