import * as React from 'react'
import { ShapeTypes } from '@naire-seisakusho/react-almagest'

import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

import { ColorPicker } from '~/components/molecules'
import { PropertiesSelect, NumericInput } from '~/components/atoms'

type Props = {
  shapeType: string
  attributes: {
    stroke: string
    strokeWidth: string
    strokeDasharray: string
  }
  effectAttributes: (attributes: Attributes) => void
  storeAttributes: (attributes: Attributes) => void
}
const PropertiesStroke: React.FC<Props> = ({
  shapeType,
  attributes,
  effectAttributes,
  storeAttributes,
}) => {
  const { stroke } = attributes

  const strokeWidth = (() => {
    if (attributes.strokeWidth !== undefined) return attributes.strokeWidth
    return stroke ? '1' : '0'
  })()

  const strokeDasharray = attributes.strokeDasharray
    ? attributes.strokeDasharray
    : ''

  const strokeWidthMin = shapeType === ShapeTypes.line ? 1 : 0
  const strokeWidthMax = 10

  const strokeWidthList = [...Array(strokeWidthMax + 1 - strokeWidthMin)].map(
    (_, i) => {
      const value = strokeWidthMin + i + ''
      const label = value !== '0' ? value : '無し'
      return { label, value }
    }
  )

  const strokeTypeList = [
    { label: '直線', value: '' },
    { label: '破線1', value: '4 4' },
    { label: '破線2', value: '8 8' },
  ]

  return (
    <div className="propertyList">
      <div className="propertyItem">
        <p className="propertyItem__name">
          <span className="inputLabel">色</span>
        </p>
        <div className="propertyItem__main">
          <ColorPicker
            color={stroke}
            // 太さ0でも色を設定したら表示されるので1を設定
            onChange={hex => {
              effectAttributes({
                stroke: hex,
                'stroke-width': strokeWidth || 1,
              })
            }}
            onChangeComplete={hex => {
              storeAttributes({ stroke: hex, 'stroke-width': strokeWidth || 1 })
            }}
          />
        </div>
      </div>

      <div className="propertyItem">
        <p className="propertyItem__name">
          <span className="inputLabel">種類</span>
        </p>
        <div className="propertyItem__main">
          <PropertiesSelect
            list={strokeTypeList}
            onChange={value => storeAttributes({ 'stroke-dasharray': value })}
            selected={strokeDasharray}
          />
        </div>
      </div>

      <div className="propertyItem">
        <p className="propertyItem__name">
          <span className="inputLabel">太さ</span>
        </p>
        <div className="propertyItem__main">
          <PropertiesSelect
            list={strokeWidthList}
            onChange={value => storeAttributes({ 'stroke-width': value })}
            selected={strokeWidth}
          />
          <NumericInput
            value={+strokeWidth}
            minimum={strokeWidthMin}
            maximum={strokeWidthMax}
            onChange={value => storeAttributes({ 'stroke-width': value })}
          ></NumericInput>
        </div>
      </div>
    </div>
  )
}

export default PropertiesStroke
