import IntraController from '~/byenv/components/IntraController'
import SideTabPanelPaletteContent from '~/byenv/components/SideTabPanelPaletteContent'
import { useDevHash } from '~/byenv/hooks/devHash'
import { useScrollKeeper } from '~/byenv/hooks/scrollKeeper'
import { useSetDropTargetRefForDragOriginal } from '~/byenv/hooks/dragOriginal'
import { isUsingBrowserContextMenu } from '~/byenv/utils/isUsingBrowserContextMenu'
import { toPersistStoreBlackList } from '~/byenv/utils/toPersistStoreBlackList'
import { sentry } from '~/byenv/utils/sentry'
import { addMaterialListOnDev } from '~/byenv/dedicated/addMaterialListOnDev'
import { alertWhenHasUrlNotFoundOnIntra } from '~/byenv/dedicated/alertWhenHasUrlNotFoundOnIntra'
import type { MaterialList } from '~/dedicated/materialList'

// どうもディレクトリ名を1単語にしたそうだったものの
// 思いつかないので区切りなしで2単語にしといた
// それとサブディレクトリにindex(import集める)置くやり方は
// 今はやめとく(スケールメリット出るようならやったらいい)

export const byenv: {
  components: {
    IntraController: typeof IntraController | null
    SideTabPanelPaletteContent: typeof SideTabPanelPaletteContent | null
  }
  dedicated: {
    addMaterialListOnDev: (materialList: MaterialList) => void
    alertWhenHasUrlNotFoundOnIntra: (materialList: MaterialList) => void
  }
  hooks: {
    useDevHash: () => void
    useScrollKeeper: () => void
    useSetDropTargetRefForDragOriginal: (ref: any) => void
  }
  utils: {
    isUsingBrowserContextMenu: (isMacCtrlToggleOn: boolean) => boolean
    toPersistStoreBlackList: (
      whiteList: string[],
      ...aditionalKeys: string[]
    ) => string[]
    sentry: () => void
  }
  literals: {
    sideTabItemPalette: string | null
  }
} = {
  components: {
    IntraController: null,
    SideTabPanelPaletteContent: null,
  },
  dedicated: {
    addMaterialListOnDev: (_) => {},
    alertWhenHasUrlNotFoundOnIntra: (_) => {},
  },
  hooks: {
    useDevHash: () => {},
    useScrollKeeper: () => {},
    useSetDropTargetRefForDragOriginal: () => {},
  },
  utils: {
    isUsingBrowserContextMenu: (_) => false,
    toPersistStoreBlackList: (_) => [],
    sentry: () => {},
  },
  literals: {
    sideTabItemPalette: null,
  },
}

const onExceptDevelopment = () => {
  byenv.utils.toPersistStoreBlackList = toPersistStoreBlackList
  byenv.utils.sentry = sentry
}

const onExceptProduction = () => {
  byenv.components.IntraController = IntraController
  byenv.dedicated.alertWhenHasUrlNotFoundOnIntra = alertWhenHasUrlNotFoundOnIntra
  byenv.utils.isUsingBrowserContextMenu = isUsingBrowserContextMenu
}

const onDevelopment = () => {
  byenv.components.SideTabPanelPaletteContent = SideTabPanelPaletteContent
  byenv.dedicated.addMaterialListOnDev = addMaterialListOnDev
  byenv.hooks.useDevHash = useDevHash
  byenv.hooks.useScrollKeeper = useScrollKeeper
  byenv.hooks.useSetDropTargetRefForDragOriginal = useSetDropTargetRefForDragOriginal
  byenv.literals.sideTabItemPalette = '素材(仮)' // もうちょっとリテラル増えたら別モジュールに
  // byenv.utils.sentry = sentry // developmentでも試したい時だけコメ解除
}

const onStaging = () => {}

const onProduction = () => {}

// たぶんifで複合条件を書くと判定優先順位が重要になってややこしくなるので
// フラットに並べる方がいい
// で複合条件は関数にまとめて、マッチするif下に置く
if (process.env.TARGET_ENV === 'development') {
  console.log(`env:dev str:${process.env.TARGET_ENV}`)
  onDevelopment()
  onExceptProduction()
}
if (process.env.TARGET_ENV === 'staging') {
  console.log(`env:stg str:${process.env.TARGET_ENV}`)
  onStaging()
  onExceptDevelopment()
  onExceptProduction()
}
if (process.env.TARGET_ENV === 'production') {
  console.log(`env:prd str:${process.env.TARGET_ENV}`)
  onProduction()
  onExceptDevelopment()
}
