import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setScrollTop, selectManeuverState } from '~/modules/maneuver'
import { useEventListener } from '~/hooks/eventListener'
import { userAgent } from '~/utils/userAgent'

// scrollTopは
// safari/edge: document.body
// それ以外: document.documentElement
// が変化するプロパティとして定義されてる
// 逆に「変化しない」とは「undefinedではなく値が固定値0」という意味
// 従ってプロパティへの代入もする事を考えると、UA判定をして
// 使うべきオブジェクトを判断しないといけない
// とりあえず開発用なのでdocumentElementだけに対応
export const useScrollKeeper = () => {
  // IE11は再render走ったら激重なので対象外
  if (userAgent.browser.ie11) return
  const dispatch = useDispatch()
  const maneuverState = useSelector(selectManeuverState)

  const scrollTopSaved = maneuverState.scrollTop
  document.documentElement.scrollTop = scrollTopSaved
  // scrollTopに0より大きい値を代入する時、react-almagestでsight.originに代入する値を取れてない
  // たぶんgetBoundingClientRectの始点はViewPortであってPageではないので、window.pageXOffset/pageYOffsetを足さないといけない
  useEventListener<any>(
    'scroll',
    () => {
      const scrollTop = document.documentElement.scrollTop
      // これで再renderされてしまう
      dispatch(setScrollTop(scrollTop))
    },
    window
  )
  // window.addEventListener('scroll', () => {
  //   const scrollTop = document.documentElement.scrollTop
  //   dispatch(setScrollTop(scrollTop))
  // })
}
