import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useOnDropShapeLife } from '~/hooks/onDropShapeLife'
import { useParams } from 'react-router-dom'
import {
  Almagest,
  useAlmagest,
  targetModule,
} from '@naire-seisakusho/react-almagest'
// import { useDrop } from 'react-dnd'
import { useEventListener } from '~/hooks/eventListener'

import {
  HotKeyGuide,
  ContextMenu,
  Modals,
  ModalOverlay,
} from '~/components/organisms'
import { GridGuide, BoneGuide, TracingMouseNotice } from '~/components/atoms'
import { ShowAttention } from '~/components/molecules'

import { useKeyEvents } from '~/hooks/keyEvents'
import { setOnSpawnShape, selectManeuverState } from '~/modules/maneuver'
import { usingOriginalContextMenu } from '~/utils/devConstants'
import { dataset } from '~/utils/ponyfill'
import { classAccessor } from '~/utils/classAccessor'

import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

import { byenv } from '~/byenv/'
const {
  utils: { isUsingBrowserContextMenu },
  hooks: { useSetDropTargetRefForDragOriginal },
} = byenv

const { clear, selectTargetId } = targetModule
const sightId = 'sight'

const Editor: React.FC = () => {
  const dispatch = useDispatch()
  const { endOnDropShapeLife } = useOnDropShapeLife()
  const targetId = useSelector(selectTargetId)
  const maneuverState = useSelector(selectManeuverState)

  // 裏表を切り替えたらターゲットを解除
  const { face } = useParams<{ face: string }>()
  React.useEffect(() => {
    dispatch(clear())
  }, [face])

  const [contextMenuOpen, setContextMenuOpen] = React.useState(false)
  const [contextMenuPosition, setContextMenuPosition] = React.useState({
    x: 0,
    y: 0,
  })

  const { top: sightTop, left: sightLeft } = document
    .getElementById(sightId)
    ?.getBoundingClientRect() || { top: 0, left: 0 }

  useKeyEvents()

  // 開く
  const openContext = (event: React.MouseEvent) => {
    if (!usingOriginalContextMenu) return
    if (isUsingBrowserContextMenu(maneuverState.isMacCtrlToggleOn)) return

    event.preventDefault()

    const { clientX, clientY } = event

    setContextMenuPosition({ x: clientX - sightLeft, y: clientY - sightTop })
    setContextMenuOpen(true)

    if ((event.target as any)?.getAttribute('id') === 'paper-bg')
      dispatch(clear())
  }

  // 閉じる
  const closeContext = () => contextMenuOpen && setContextMenuOpen(false)

  // FIXME: paperをクリックした時用じゃなくなったんなら名前ちゃんと直しましょう
  const clickPaper = (event: React.MouseEvent) => {
    // onDropShape実行直後に後続処理走ると、ドロップした図形をアクティブにする
    // 処理に到達する前にsightがクリックされた扱いになるので、1回スッキプ
    if (maneuverState.onSpawnShape) {
      dispatch(setOnSpawnShape(false))
      return
    }

    if (contextMenuOpen) {
      setContextMenuOpen(false)
      return
    }

    if ((event?.target as any)?.getAttribute('id') !== 'paper-bg') return
    dispatch(clear())
  }

  const { svgJson, almagest } = useAlmagest()

  React.useEffect(() => {
    if (!almagest) return
    // almagest.settingsを変更するならここで
    // almagest.settings.preventProtrude = true
  }, [almagest])
  ;(function (e, d, w) {
    if (!e.composedPath) {
      e.composedPath = function () {
        if (this.path) {
          return this.path
        }
        let target = this.target

        this.path = []
        while (target?.parentNode !== null) {
          this.path.push(target)
          target = target?.parentNode
        }

        this.path.push(d, w)
        return this.path
      }
    }
  })(Event.prototype as any, document, window)

  const getEventPath = (event) =>
    event.path || (event.composedPath && event.composedPath())

  const sight = React.useRef<HTMLDivElement>(null)
  useSetDropTargetRefForDragOriginal(sight)

  const addingShapeNoticeRef = React.useRef<HTMLDivElement>(null)
  const isAddingShape = () =>
    maneuverState.onDropShape && maneuverState.onDropShape.createElement
  const isClickedHome = (event) => event.target.id === 'home'
  // ドロップキャンセルを無視するエリア(モーダルとオーバーレイとsight)か
  const isCursorOnCancelOnDropArea = (event) =>
    !getEventPath(event).some(
      (dom) =>
        dom !== window &&
        dom !== document &&
        dataset(dom)?.class === 'ignoreCancelOnDrop'
    )
  const isCursorOnPaper = (event) =>
    getEventPath(event).some((dom) => dom.id === sightId)
  const isAddingShapeAndCursorOnCancelOnDropArea = (event) =>
    isAddingShape() && isCursorOnPaper(event)

  // これは開発時のリロード後state保持の時だけ起きる
  const isKeepingOnDropShapeAfterReloadOnDev = () =>
    maneuverState.onDropShape && !maneuverState.onDropShape.createElement
  const addingShapeClassName = 'addingShape'
  useEventListener<any>(
    'mousemove',
    (event) => {
      const addingShapeNotice = addingShapeNoticeRef?.current as any
      const addingShapeNoticeClacc = classAccessor(addingShapeNotice)(
        addingShapeClassName
      )
      if (!isAddingShapeAndCursorOnCancelOnDropArea(event)) {
        if (addingShapeNotice) addingShapeNoticeClacc.remove()
        return
      }
      addingShapeNoticeClacc.add()
      const x = event.pageX - window.pageXOffset
      const y = event.pageY - window.pageYOffset
      addingShapeNotice.style.transform = `translate(${x}px, ${y}px)`
    },
    document,
    true
  )
  useEventListener<any>(
    'click',
    (event) => {
      // isAddingShapeでhomeをクリックしてしまう事がある
      if (!isAddingShape() || isClickedHome(event)) return
      if (isCursorOnCancelOnDropArea(event)) {
        if (isAddingShape()) endOnDropShapeLife()
        return
      }

      const addingShapeNotice = addingShapeNoticeRef?.current as any
      classAccessor(addingShapeNotice)(addingShapeClassName).remove()
      // onDropShape実行直後に後続処理走ると、ドロップした図形をアクティブにする
      // 処理に到達する前にsightがクリックされた扱いになるので、1回スッキプ
      dispatch(setOnSpawnShape(true))
      // これは開発時のリロード後state保持の時だけ起きる
      if (isKeepingOnDropShapeAfterReloadOnDev()) {
        endOnDropShapeLife()
        return
      }
      maneuverState.onDropShape.createElement({
        x: event.pageX,
        y: event.pageY,
      })
      endOnDropShapeLife()
    },
    document,
    true
  )

  if (!svgJson) return null

  // とりあえずガイド切り替えやってみる
  // 一応動いた
  const isShowingPrintingGuide = maneuverState.isShowingPrintGuide
  const printGuide = document.querySelector('#paper #guide') as HTMLElement
  if (isShowingPrintingGuide) {
    if (printGuide) printGuide!.style.display = 'inline'
  } else {
    if (printGuide) printGuide!.style.display = 'none'
  }

  return (
    <div
      className={switcss({
        designed: 'editor',
        framework: 'h-full',
        useFramework: false,
      })}
    >
      <div
        id={sightId}
        className={`sight ${
          maneuverState.onDropShape &&
          !maneuverState.onSpawnShape &&
          !isKeepingOnDropShapeAfterReloadOnDev()
            ? addingShapeClassName
            : ''
        }`}
        data-class="ignoreCancelOnDrop"
        ref={sight}
        onClick={clickPaper}
        onContextMenu={openContext}
      >
        <Almagest />
      </div>
      <ShowAttention />
      {/* <HotKeyGuide /> */}
      <GridGuide isActive={maneuverState.isShowingGridGuide} />
      <BoneGuide isActive={maneuverState.isShowingBoneGuide} />
      <ContextMenu
        open={contextMenuOpen}
        position={contextMenuPosition}
        targetId={targetId}
        close={closeContext}
      />
      {/* これだとうまくいかない */}
      {/*
        <TracingMouseNotice
          ref={addingShapeNoticeRef}
          mainClassName="addingShapeNotice"
          text="Escキーでキャンセルする事ができます。"
        />
      */}
      {/* 状態クラス'addingShape' と座標はプログラムから */}
      <div
        ref={addingShapeNoticeRef}
        className={`tracingMouseNotice addingShapeNotice`}
      >
        <p>Escキーでキャンセルする事ができます。</p>
      </div>
    </div>
  )
}

export default Editor
