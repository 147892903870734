import { createSvgson } from '~/utils/createSvgson'
import { arrayToJson } from '~/utils/arrayToJson'
import {
  ShapeTypes,
  ShapeTypeNameDefines,
  ShapeTypeOriginSizesToAttrbutes,
} from '@naire-seisakusho/react-almagest'
import { useOnDropSourceToEditorCreator } from '~/hooks/onDropSourceToEditorCreator'

type ShapeSources = {
  [key: string]: {
    [key: string]: any | React.FC
  }
}

export type OnPaletteDefines = {
  [key in string]: {
    shapeType: ShapeTypes
    title: string
    droppedInitSizes: {
      width: number
      height: number
    }
    drawedAttributes: {
      [key: string]: string | number
    }
  }
}

export const useOnPaletteSourceDrawing = (
  onPaletteDefines: OnPaletteDefines,
  sizeOnPaletteShorterSide: number
): any => {
  const onDropSourceToEditorCreator = useOnDropSourceToEditorCreator()

  const createShapeSource = (shapeTypeOnPalette: string) => {
    const onPaletteDefine = onPaletteDefines[shapeTypeOnPalette]
    const {
      shapeType,
      droppedInitSizes,
      drawedAttributes,
      title,
    } = onPaletteDefine
    const shapeTypeNameDefine = ShapeTypeNameDefines[shapeType]
    const width = droppedInitSizes.width
    const height = droppedInitSizes.height

    // ドロップ後のサイズとパレットでの短辺上限の長さから、パレット上でのサイズを算出
    const onPaletteShapeSizes = (() => {
      const division = (() => {
        if (width === 0 && height === 0) return 1
        if (width === 0) return height
        if (height === 0) return width
        return height < width ? height : width
      })()
      const ratioToOnPalletSize = sizeOnPaletteShorterSide / division
      return {
        width: width * ratioToOnPalletSize,
        height: height * ratioToOnPalletSize,
      }
    })()

    // 辺の長さがゼロなら引数の短辺サイズを保証してSVGサイズとする
    const onPaletteSourceSizes = {
      width: onPaletteShapeSizes.width || sizeOnPaletteShorterSide,
      height: onPaletteShapeSizes.height || sizeOnPaletteShorterSide,
    }

    // 辺の長さがゼロで短辺保証された場合に、SVG要素内の図形要素をセンタリング
    const onPaletteShapeOrigin = {
      x: (onPaletteSourceSizes.width - onPaletteShapeSizes.width) / 2,
      y: (onPaletteSourceSizes.height - onPaletteShapeSizes.height) / 2,
    }

    const svgson = createSvgson.tag(shapeTypeNameDefine.tag).props({
      attributes: drawedAttributes,
      children: [],
    })

    const onDropSourceToEditor = onDropSourceToEditorCreator.drawing(
      shapeTypeOnPalette,
      onPaletteDefine,
      svgson
    )

    return {
      shapeType,
      droppedInitSizes,
      onPaletteSourceSizes,
      onPaletteShapeOrigin,
      onPaletteShapeSizes,
      title,
      svgson,
      onDropSourceToEditor,
    }
  }

  const createShapeSources = (): ShapeSources =>
    arrayToJson(Object.keys(onPaletteDefines), (json, shapeTypeOnPalette) => {
      json[shapeTypeOnPalette] = createShapeSource(shapeTypeOnPalette)
    })

  return createShapeSources()
}
