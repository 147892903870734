import * as React from 'react'

// FIXME:
// 使用箇所を洗い出して引数の順番をいずれ要リファクタリング
// ([element,] eventName, handler, [useCapture])がネイティブの
// addEventListenerに近いオーバーロード(省略時のデフォが
// documentである事が本当に必要なのかも要検討)
export const useEventListener = <T>(
  eventName: string,
  handler: (event: T) => void,
  element: Window | Document | HTMLElement | SVGElement = document,
  useCapture: boolean = false
) => {
  const savedHandler = React.useRef<any>(null)

  React.useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  React.useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    type EventListener = (event: Event) => void
    const eventListener: EventListener = event => savedHandler.current(event)

    element.addEventListener(eventName, eventListener, useCapture)

    return () => element.removeEventListener(eventName, eventListener)
  }, [eventName, element])
}
