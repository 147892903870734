import * as React from 'react'
import { useDispatch } from 'react-redux'
import { SketchPicker } from 'react-color'
import { switcss } from '~/utils/switcss'
import { setShowingColorPicker } from '~/modules/maneuver'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

type Props = {
  color: string
  onChange: (hex: string) => void
  onChangeComplete: (hex: string) => void
}
const ColorPicker: React.FC<Props> = ({
  color,
  onChange,
  onChangeComplete,
}) => {
  const dispatch = useDispatch()
  const [isShow, setIsShow] = React.useState<boolean>(false)

  const setShowing = (isShowing: boolean) => 
    [
      setIsShow,
      isShowing => dispatch(setShowingColorPicker(isShowing)),
    ].forEach(setter => setter(isShowing))

  const handleClick = () => setShowing(true)
  const handleClose = () => setShowing(false)

  return (
    <div>
      <div
        className={switcss({
          designed: 'colorSelect',
          framework:
            'p-2 bg-white rounded-sm inline-block cursor-pointer shadow-sm',
          useFramework: false,
        })}
        onClick={handleClick}
      >
        <div
          className={switcss({
            designed: 'colorSelect__thumbnail',
            framework: 'w-16 h-6 rounded',
            useFramework: false,
          })}
          style={{
            background: color,
          }}
        />
        <div className="colorSelect__code">
          {color}
        </div>
      </div>
      {isShow ? (
        <div
          className='colorPicker'
        >
          <div
            className='colorPicker__overlay'
            onClick={handleClose}
          />
          <div className="colorPicker__main">
            <SketchPicker
              color={color}
              onChange={({ hex }) => onChange(hex)}
              onChangeComplete={({ hex }) => onChangeComplete(hex)}
              // 指定出来るらしいけど動作しないので default width を外すために空文字を指定
              presetColors={[]}
              disableAlpha={true}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default ColorPicker
