import * as React from 'react'
import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

type Props = {
  show: boolean
  callback: () => void
}
const ContextMenuItem: React.FC<Props> = ({ show, callback, children }) => {
  if (!show) return null

  return (
    <div
      className={switcss({
        designed: '',
        framework:
          'block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white',
        useFramework: true,
      })}
      onClick={callback}
    >
      {children}
    </div>
  )
}

export default ContextMenuItem
