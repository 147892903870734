// byenvに移すべきサブコンポーネント有無を要確認
import * as React from 'react'
import {
  ShapeTypes,
  ShapeTypeOriginSizesToAttrbutes,
} from '@naire-seisakusho/react-almagest'
import DragSourceDrawing from '~/byenv/components/sub/DragSourceDrawing'
import { baseWidthToTriangleEquilateralHeight } from '~/byenv/utils/calc'
import {
  useOnPaletteSourceDrawing,
  OnPaletteDefines,
} from '~/hooks/onPaletteSourceDrawing'

type Sizes = {
  width: number
  height: number
}

const triangleEquilateralBaseWidth = 250

const droppedInitSizeTypes: {
  [key: string]: Sizes
} = {
  horizontalLine: {
    width: 300,
    height: 0,
  },
  horizontalLonger: {
    width: 300,
    height: 200,
  },
  verticalLonger: {
    width: 200,
    height: 300,
  },
  equalRatio: {
    width: 250,
    height: 250,
  },
  triangleEquilateral: {
    width: triangleEquilateralBaseWidth,
    height: baseWidthToTriangleEquilateralHeight(triangleEquilateralBaseWidth),
  },
}

const colors = {
  basis: '#777',
  strokeExceptLine: '#fff',
}
const drawedAttributes = {
  lineSolid: {
    stroke: colors.basis,
  },
  lineDotted: {
    stroke: colors.basis,
    strokeDasharray: '4 4', // ケバブはキャメルに
  },
  exceptLine: {
    stroke: colors.strokeExceptLine,
    fill: colors.basis,
  },
}

// line以外は同じdrawedAttributesではあるものの、動的にやると忘却・見落としリスクも
const onPaletteDefines: OnPaletteDefines = {
  lineSolid: {
    shapeType: ShapeTypes.line,
    title: '直線',
    droppedInitSizes: droppedInitSizeTypes.horizontalLine,
    drawedAttributes: drawedAttributes.lineSolid,
  },
  lineDotted: {
    shapeType: ShapeTypes.line,
    title: '破線',
    droppedInitSizes: droppedInitSizeTypes.horizontalLine,
    drawedAttributes: drawedAttributes.lineDotted,
  },
  square: {
    shapeType: ShapeTypes.square,
    title: '正方形',
    droppedInitSizes: droppedInitSizeTypes.equalRatio,
    drawedAttributes: drawedAttributes.exceptLine,
  },
  rect: {
    shapeType: ShapeTypes.rect,
    title: '矩形',
    droppedInitSizes: droppedInitSizeTypes.horizontalLonger,
    drawedAttributes: drawedAttributes.exceptLine,
  },
  circle: {
    shapeType: ShapeTypes.circle,
    title: '真円',
    droppedInitSizes: droppedInitSizeTypes.equalRatio,
    drawedAttributes: drawedAttributes.exceptLine,
  },
  ellipse: {
    shapeType: ShapeTypes.ellipse,
    title: '楕円',
    droppedInitSizes: droppedInitSizeTypes.horizontalLonger,
    drawedAttributes: drawedAttributes.exceptLine,
  },
  triangleIsosceles: {
    shapeType: ShapeTypes.triangleIsosceles,
    title: '二等辺三角形',
    droppedInitSizes: droppedInitSizeTypes.equalRatio,
    drawedAttributes: drawedAttributes.exceptLine,
  },
  triangleRightLeft: {
    shapeType: ShapeTypes.triangleRightLeft,
    title: '直角三角形(左直角)',
    droppedInitSizes: droppedInitSizeTypes.equalRatio,
    drawedAttributes: drawedAttributes.exceptLine,
  },
  triangleRightRight: {
    shapeType: ShapeTypes.triangleRightRight,
    title: '直角三角形(右直角)',
    droppedInitSizes: droppedInitSizeTypes.equalRatio,
    drawedAttributes: drawedAttributes.exceptLine,
  },
  triangleEquilateral: {
    shapeType: ShapeTypes.triangleEquilateral,
    title: '正三角形',
    droppedInitSizes: droppedInitSizeTypes.triangleEquilateral,
    drawedAttributes: drawedAttributes.exceptLine,
  },
}

const sizeOnPaletteShorterSide = 50

const PaletteDrawings: React.FC = () => {
  const shapeOnPaletteSources = useOnPaletteSourceDrawing(
    onPaletteDefines, // この中で定義されてるのはドロップ後の縦横
    sizeOnPaletteShorterSide // この数値はパレットでの短辺の長さ
  )

  return (
    <div className="palette">
      {Object.keys(shapeOnPaletteSources).map((shapeTypeOnPallete) => {
        const {
          shapeType,
          svgson,
          onPaletteShapeOrigin,
          onPaletteShapeSizes,
          onPaletteSourceSizes,
          title,
          onDropSourceToEditor,
        } = shapeOnPaletteSources[shapeTypeOnPallete]
        const ShapeOnPaletteTagName = svgson.name

        const drawedAttributes = svgson.attributes
        const positionAttributes = ShapeTypeOriginSizesToAttrbutes[shapeType]
          .origin(onPaletteShapeOrigin)
          .sizes(onPaletteShapeSizes)

        const ShapeOnPalette = () => (
          <ShapeOnPaletteTagName
            {...{
              ...drawedAttributes,
              ...positionAttributes,
            }}
          />
        )

        return (
          <DragSourceDrawing
            title={title}
            onDrop={onDropSourceToEditor}
            key={onDropSourceToEditor.key}
          >
            <svg
              width={onPaletteSourceSizes.width}
              height={onPaletteSourceSizes.height}
              viewBox={`0 0 ${onPaletteSourceSizes.width} ${onPaletteSourceSizes.height}`}
            >
              <ShapeOnPalette />
            </svg>
          </DragSourceDrawing>
        )
      })}
    </div>
  )
}

export default PaletteDrawings
