import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AlmagestProvider } from '@naire-seisakusho/react-almagest'

import { Home, Confirm, NotFound } from '~/pages'
import Guard from '~/routes/guard'

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Guard />
        </Route>

        <Route path="/:frontToken/:backToken/:face" exact>
          {/* 存在させておかないとContextを保持できない */}
          <AlmagestProvider>
            <Home face="front" />
          </AlmagestProvider>

          <AlmagestProvider>
            <Home face="back" />
          </AlmagestProvider>
        </Route>

        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
