import type { MaterialList } from '~/dedicated/materialList'

import { MaterialTypes } from '~/dedicated/materialTypes'

const usingIrregularFilesForDev = false

export const addMaterialListOnDev = (materialList: MaterialList) => {
  if (!usingIrregularFilesForDev) return
  materialList.unshift(
    {
      type: MaterialTypes.backgroundImage,
      name: '横がはみ出る背景',
      url: {
        thumbnail: '/materials/background/thumbnail/test_longer_width.jpg',
        image: '/materials/background/test_longer_width.png',
      },
    },
    {
      type: MaterialTypes.partsImage,
      name: '部品としての画像',
      url: {
        thumbnail: '/materials/background/thumbnail/test_image_lunasea.jpg',
        image: '/materials/background/test_image_lunasea.png',
      },
    },
    {
      type: MaterialTypes.svgImage,
      name: 'nest',
      url: {
        thumbnail: '/svg/nestjs-seeklogo.com.jpg',
        image: '/svg/nestjs-seeklogo.com.svg',
      },
    }
  )
}
