import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { historiesModule } from '@naire-seisakusho/react-almagest'

import { useSaveDataMutation } from '~/hooks/apolloAction'
import { PreviewSide } from '~/components/molecules'

import { useSpica } from '~/context'

const { selectHistoryId } = historiesModule

const Preview: React.FC = () => {
  const { frontToken, backToken } = useParams()

  // プレビュー画像をキャッシュさせないためにつけてるGETパラメタに historyId を渡して
  // 変更がなければキャッシュから読み込まれることを祈る ブラウザ次第
  const historyId = useSelector(selectHistoryId)

  const [frontSaveMutation, front] = useSaveDataMutation()
  const [backSaveMutation, back] = useSaveDataMutation()

  React.useEffect(() => {
    frontSaveMutation({ variables: { token: frontToken as string } })
    backSaveMutation({ variables: { token: backToken as string } })
  }, [])

  const getTokenizedFileName = saveData => saveData.data?.saveData.token
  const { uchiwaSizeState } = useSpica()
  const [uchiwaSize] = uchiwaSizeState
  return (
    <div className="preview">
      <div className="preview__sides">
        {/* 表面 */}
        <PreviewSide
          uchiwaSize={uchiwaSize}
          sideName="おもて"
          historyId={historyId}
          tokenizedFileName={getTokenizedFileName(front)}
          isWaitingSaveData={front.loading}
        />
        {/* 裏面 */}
        <PreviewSide
          uchiwaSize={uchiwaSize}
          sideName="うら"
          historyId={historyId}
          tokenizedFileName={getTokenizedFileName(back)}
          isWaitingSaveData={back.loading}
        />
      </div>
    </div>
  )
}

export default Preview
