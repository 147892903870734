import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  draggerModule,
  historiesModule,
  targetModule,
  inputDeviceModule,
} from '@naire-seisakusho/react-almagest'

import modalsReducer from '~/modules/modals'
import maneuverReducer from '~/modules/maneuver'
import doingHotKeyTasksReducer from '~/modules/doingHotKeyTasks'
import uploadedImagesReducer from '~/modules/uploadedImages'
import shapesReducer from '~/modules/shapes'
import editTextElementReducer from '~/modules/editTextElement'

import { byenv } from '~/byenv'
const {
  utils: { toPersistStoreBlackList },
} = byenv

const reducers = {
  dragger: draggerModule.draggerReducer,
  histories: historiesModule.historiesReducer,
  target: targetModule.targetReducer,
  inputDevice: inputDeviceModule.inputDeviceReducer,
  modals: modalsReducer,
  maneuver: maneuverReducer,
  doingHotKeyTasks: doingHotKeyTasksReducer,
  uploadedImages: uploadedImagesReducer,
  shapes: shapesReducer,
  editTextElement: editTextElementReducer,
}

const reducerKeys = Object.keys(reducers)
const excludingReducerKeys = ['histories']
const persistConfigCommonList = reducerKeys.filter(
  (key) => !excludingReducerKeys.includes(key)
)

const persistConfig = {
  key: 'spica',
  storage,
  whitelist: persistConfigCommonList,
  blacklist: toPersistStoreBlackList(persistConfigCommonList),
}

const rootReducer = combineReducers(reducers)
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [],
})

const persistor = persistStore(store)

export { store, persistor }
