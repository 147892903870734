import * as React from 'react'

import { SideContentsBox, PhaseNav } from '~/components/organisms'

import { switcss } from '~/utils/switcss'
/*
className={switcss({
  designed: '',
  framework: '',
  useFramework: true,
})}
*/

const Sidebar: React.FC = () => {
  return (
    <div
      className={switcss({
        designed: 'sideBar',
        framework: 'overflow-hidden m-0 p-0 w-full h-auto',
        useFramework: false,
      })}
    >
      <div className="sideBar__sub">
        <PhaseNav />
      </div>

      <div className="sideBar__main">
        <div className="sideBar__contents">
          <SideContentsBox />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
