import * as React from 'react'

type UseProvideSpica = ReturnType<typeof useProvideSpica>

const SpicaContext = React.createContext<UseProvideSpica>({} as UseProvideSpica)

const useProvideSpica = () => {
  const isLoadingState = React.useState<boolean>(true)
  const frontThumbnailState = React.useState<Element>()
  const backThumbnailState = React.useState<Element>()
  const uchiwaSizeState = React.useState<string>()
  // FIXME: 変数名かえる
  const generalState = React.useState<any>({})

  return {
    isLoadingState,
    frontThumbnailState,
    backThumbnailState,
    uchiwaSizeState,
    generalState,
  }
}

export const useSpica = () => React.useContext<UseProvideSpica>(SpicaContext)

export const SpicaProvider: React.FC = ({ children }) => (
  <SpicaContext.Provider value={useProvideSpica()}>
    {children}
  </SpicaContext.Provider>
)
