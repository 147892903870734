import * as React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Layers, Properties, GuideList } from '~/components/organisms'

import { byenv } from '~/byenv'
const {
  literals: { sideTabItemPalette },
  components: { SideTabPanelPaletteContent },
} = byenv

const toTabArray = <T extends string | React.FC>(
  defaults: T[],
  byenvValue: T | null
) => (byenvValue ? [byenvValue, ...defaults] : defaults)

const SideContentsBox: React.FC = () => {
  const tabItems: string[] = toTabArray(
    ['情報', '要素一覧', 'ガイドの表示'],
    sideTabItemPalette
  )
  const tabPanelContents: React.FC[] = toTabArray(
    [Properties, Layers, GuideList],
    SideTabPanelPaletteContent
  )
  // 少なくともcomponent配列はFC内で宣言しないといけない
  return (
    <div className="sideContentsBox">
      <Tabs className="sideContentsBox__tabs">
        <TabList className="sideContentsBox__tabList">
          {tabItems.map((tabItemContent, i) => (
            <Tab
              className="sideContentsBox__tabItem"
              selectedClassName="-active"
              key={i}
            >
              {tabItemContent}
            </Tab>
          ))}
        </TabList>
        {tabPanelContents.map((TabPanelContent, i) => (
          <TabPanel
            className="sideContentsBox__tabPanel"
            selectedClassName="-active"
            key={i}
          >
            <TabPanelContent />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  )
}
export default SideContentsBox
