import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'uploadedImages',
  initialState: {
    images: [],
  },
  reducers: {
    setUploadedImages: (state, action) => {
      state.images = action.payload
    },
  },
})

export const { setUploadedImages } = slice.actions

export const selectUploadedImages = state => state.uploadedImages.images

export default slice.reducer
