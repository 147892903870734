import React from 'react'
import { useDragOriginal } from '~/byenv/hooks/dragOriginal'

type Props = {
  title: string
  onDrop: any
}
const DragSourceDrawing: React.FC<Props> = ({ children, title, onDrop }) => {
  // この外側のwrapper何のためか忘れた
  const ref = React.useRef<HTMLDivElement>(null)

  const [{ opacity, cursor }, dragSourceRef] = useDragOriginal(onDrop)

  // .dev-temp-parentがdraggable=true
  return (
    <div
      ref={ref}
      className="drag-source drag-source-drawing"
      // title={title}
      style={{
        opacity,
        cursor,
      }}
      data-title={title}
    >
      <div ref={dragSourceRef} className="dev-temp-parent">
        {children}
      </div>
    </div>
  )
}
export default DragSourceDrawing
