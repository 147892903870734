import React from 'react'

type Props = {
  mainClassName: string
  text: string
  ref: any
}

const TracingMouseNotice: React.FC<Props> = ({ mainClassName, text, ref }) => {
  if (!ref) return null
  return (
    <div ref={ref} className={`tracingMouseNotice ${mainClassName}`}>
      <p>{text}</p>
    </div>
  )
}
export default TracingMouseNotice
